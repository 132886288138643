<div id="cart_side" class="add_to_cart" [ngClass]="direction" [class.openSide]="productService?.OpenCart">
    <a href="javascript:void(0)" class="overlay" (click)="closeCart()"></a>
    <div class="cart-inner">
        <div class="cart_top">
            <h3>Giỏ hàng của bạn</h3>
            <div class="close-cart" (click)="closeCart()">
                <a href="javascript:void(0)">
                    <i class="fa fa-times" aria-hidden="true"></i>
                </a>
            </div>
        </div>
        <div class="cart_media" *ngIf="products.length">
            <ul class="cart_product">
                <li *ngFor="let product of products">
                    <div class="media">
                        <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"> -->
                            <img class="me-3" [src]="product.image ? product.image : 'assets/images/product/placeholder.jpg'" [alt]="product.productName">
                        <!-- </a> -->
                        <div class="media-body">
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"> -->
                                <h4>{{ product?.productName | titlecase }}</h4>
                            <!-- </a> -->
                            <h4>
                                <span>
                                {{ product?.quantity }} x 
                                {{ (product.prices[0].price ) | currency:'VND':'symbol':null:'vi-VN' }}
                                </span>
                            </h4>
                        </div>
                    </div>
                    <div class="close-circle">
                        <a href="javascript:void(0)" (click)="removeItem(product)">
                            <i class="ti-trash" aria-hidden="true"></i>
                        </a>
                    </div>
                </li>
            </ul>
            <ul class="cart_total">
                <li>
                    <div class="total">
                        <h5>
                            subtotal :
                            <span>{{ getTotal | async |  currency:'VND':'symbol':null:'vi-VN' }}</span>
                        </h5>
                    </div>
                </li>
                <li>
                    <div class="buttons">
                        <a [routerLink]="['/pages/cart']" class="btn btn-solid btn-xs view-cart">Giỏ hàng</a>
                        <a [routerLink]="['/pages/checkout']" class="btn btn-solid btn-xs checkout">Đặt hàng</a>
                    </div>
                </li>
            </ul>
        </div>
        <div class="col-sm-12 empty-cart-cls text-start" *ngIf="!products.length">
            <h4 class="mt-3">
              <strong>Giỏ hàng trống!</strong>
            </h4>
            <a [routerLink]="['/shop']" class="btn btn-solid">Tiếp tục mua hàng</a>
        </div>
    </div>
</div>