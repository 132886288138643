import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuickViewComponent } from 'src/app/shared/components/modal/quick-view/quick-view.component';
import { CartModalComponent } from 'src/app/shared/components/modal/cart-modal/cart-modal.component';
import { ProductService } from 'src/app/shared/services/product.service';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { ppid } from 'process';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { addToCart } from 'src/app/store/actions/cart.action';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
@Component({
  selector: 'app-product-box',
  templateUrl: './product-box.component.html',
  styleUrls: ['./product-box.component.scss']
})
export class ProductBoxComponent implements OnInit, AfterViewInit {
  @Input() product: ProductItems;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @ViewChild("confirmModal") ConfirmModal: ConfirmModalComponent;
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  customerId: string;
  isSmallScreen: boolean = false;
  productSelectAddToCart: ProductItems;
  slug: string
  productTag :any = productTag;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService,private destroy: DestroySubsribeService, private route: ActivatedRoute, private toastrService: ToastrService,private store: Store<RootState>, private loadingBar: LoadingBarService, private router: Router) { 
    this.checkScreenSize();

  }

  ngOnInit(): void {
    this.onLoadCustomerLogin();
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      this.slug = slug// In ra giá trị slug từ URL
    });
  }
  onLoadCustomerLogin() {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.customerId = null;
      this.customerId = customerLogin._id;
    })
  }
  async resetCart() {
       const status = await this.productService.resetCart();
       return status
  }
  async addToCart(product: any, resetCart?: boolean) {
    this.productSelectAddToCart = null;
    if (localStorage.getItem('shopSlug')) {
     if (JSON.parse(localStorage['shopSlug']) != this.slug) {
        this.ConfirmModal.openModal('Chọn cửa hàng khác sẽ cài lại giỏ hàng!');
        this.productSelectAddToCart = product;
        return
      } 
    }
    
    if (!product || !product?.productName || !product?._id) {
      return this.toastrService.error('Vui lòng thử lại')
    }
    this.loadingBar.start();      
    const status = await this.productService.addToCart(product, this.customerId, this.slug, resetCart);
    if(!status)  return 
   
    this.toastrService.success(`Đã thêm <b >${product?.productName && product.productName.substring(0, 15)+'...'}</b> vào giỏ hàng`)
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenSize();
  }

  private checkScreenSize() {
    this.isSmallScreen = window.innerWidth < 480;
  }
  handleShowProduct(product: ProductItems) {
    if (!this.isSmallScreen ) return
    this.QuickView.openModal(product)
  }
  checkTags(tagToCheck: string, product: ProductItems): boolean {
    if(!product.productTag) return false
    return product?.productTag.includes(tagToCheck);
  }
  ngAfterViewInit() {
    this.ConfirmModal.resultConfirm.subscribe((result) => {
      if (result) {
          if (this.resetCart()) {
            this.addToCart(this.productSelectAddToCart, true);
            //reset giỏ hàng
            return
          } else {
            return this.toastrService.info('Cài lại giỏ hàng không thành công');
          }
      }
    });
  }
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
export const productTag = {
  sale1: 'Khuyễn Mãi',
  sale2: 'Khuyến mãi',
  new: 'Món Mới'
}