<ng-template class="theme-modal" #scanQR let-modal>
    <input type="file" (change)="handleAddImg($event)" [hidden]="true" #inpFile [accept]="'.jpg, .png, .gif, .jpeg'">
    <div class="modal-content quick-view-modal">
        <div class="modal-header modal-bg">
            <h3 >Đăng nhập với QR</h3>
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
                <div class="upload-qr" >
                    <ng-container *ngIf="!imageUrl else fileScan">
                        <div class="action-qr" (click)="inpFile.click()">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </div>  
                    </ng-container>
                    
                    <ng-template #fileScan>
                        <div class="center">
                            <div class="square">
                                <img [src]="imageUrl" /> 
                              <div class="scan" *ngIf="loadQrcode">
                              </div>
                            </div>
                        </div>
                    </ng-template>
                    <p *ngIf="imageUrl && !loadQrcode" class="m-0 action-select-qr" (click)="handleSelectQr(); inpFile.value = null ">Chọn QR khác</p>
                    <div class="loading-spinner mt-2" *ngIf="imageUrl && loadQrcode" style="margin: 0 auto; "></div>
                    <button (click)="handleLogin()" class="btn btn-solid mt-3 d-flex btn-login" *ngIf="imageUrl && !loadQrcode && dataLogin">
                        Đăng nhập
                    </button>
                </div>
        </div>
    </div>
</ng-template>
<app-confirm-modal #confirmCart></app-confirm-modal>