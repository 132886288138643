import  CustomersManager  from '@pickdi/common/lib/model/manager/CustomersManager';
import { WebOrders } from '@pickdi/common/lib/model/WebOrders';
import WebOrdersManager from '@pickdi/common/lib/model/manager/WebOrdersManager';
import TablesManager from '@pickdi/common/lib/model/manager/TableManager';
import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as _ from 'lodash';
import { Store } from '@ngrx/store';
import { showLoadding } from '../actions/loadding.action';
@Injectable()
export class OrdersService {
  constructor(
    private loadingBar: LoadingBarService,
    private store: Store
    ) { }
  async onCreateOrder(action): Promise<HttpStatus<WebOrders>>  {
    let data = _.cloneDeep(action);
    if (data?.type) {
      delete data['type'];
    }
    this.loadingBar.start();
    const ordersManager = new WebOrdersManager(null);
    ordersManager.classInfo.endPoint = 'eCommerce/order';
    return ordersManager.insert(data);
  }

  async onLoadTableByShop(action): Promise<HttpStatus<WebOrders>>  {
    let data = _.cloneDeep(action);
    if (data?.type) {
      delete data['type'];
    }
    this.loadingBar.start();
    const tableManager = new TablesManager(null);
    tableManager.classInfo.endPoint = 'eCommerce/order/getAllTable';
    return tableManager.search(data);
  }
  async onLoadOrderList(action): Promise<HttpStatus<any>>  {
    this.store.dispatch(showLoadding());
    let data = _.cloneDeep(action);
    if (data?.type) {
      delete data['type'];
    }
    this.loadingBar.start();
    const oderManager = new CustomersManager(null);
    oderManager.classInfo.endPoint = 'eCommerce/customer/orderHistory';
    return oderManager.search({searchCriterial: data});
  }

}