import { Action, State } from '@ngrx/store';
import { login, loadAuthen, loginSucess, changeViewScreen, register, registerSucess, registerMessage, loginMessage, clearStateAuth } from '../actions/auth.action';
import { AuthenState } from '../entities/state.entity';
import { AuthActions } from './../actions/auth.action'
import { createReducer, on } from '../utils/store.util';

const initialState: AuthenState = {
  logged: false,
  _id: null,
  viewManager: false,
  customerRegister : null,
  errorRegister: null,
  customerCurrent: null,
  loginMessage: null
};

export const reducer = createReducer(
  initialState,
  on(loadAuthen, (state, action) => ({
    ...state,
    logged: action.logged,
    _id: action._id,
  })),
  on(login, (state, action) => ({
    ...state
  })),
  
  on(register, (state, action) => ({
    ...state,
  })),
  on(registerSucess, (state, action) => ({
    ...state,
    customerRegister: action
  })),
  on(registerMessage, (state, action) => ({
    ...state,
    errorRegister: action
  })),
  on(loginMessage, (state, action) => ({
    ...state,
    loginMessage: action
  })),
  on(loginSucess, (state, action) => ({
    ...state,
    _id: action._id,
    customerCurrent: action
  })),
  on(changeViewScreen, (state, action) => ({
    ...state,
    viewManager: action.value
  })),
  on(clearStateAuth, (state, action) => ({
    ...state,
    logged: false,
    _id: null,
    viewManager: false,
    customerRegister : null,
    errorRegister: null,
    customerCurrent: null,
    loginMessage: null
  })),
);
export function authenReducer(state: AuthenState | undefined, action: Action) {
  if (action.type === AuthActions.logout || action.type === AuthActions.login) {
    state = initialState
  }
  return reducer(state, action)
}