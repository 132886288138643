import { NavService } from './nav.service';
import { Customers } from '@pickdi/common/lib/model/Customers';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from './destroySubscribe.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { loadCustomerLogin } from 'src/app/store/actions/customer.action';
import { ProductService, state } from './product.service';
import { clearCart } from 'src/app/store/actions/cart.action';



@Injectable({
	providedIn: 'root',
})

export class LoginService {

	constructor(private store : Store<RootState>, private productService: ProductService, private toastrService : ToastrService, private destroy: DestroySubsribeService , private location: Location, private router: Router, private urlCheckLogin : NavService) { }
    private onLoadCustomerLogin = (_id: string) => {
        this.store.dispatch(loadCustomerLogin({ customerId: _id }))
    
      }
    private isLoginRequired(currentPath) {
        // Kiểm tra xem URL hiện tại có trong mảng URLCHECKLOGIN hay không
        return this.urlCheckLogin.URLCHECKLOGIN.includes(currentPath);
      }
    private checkUrlLogin(customer) {
        let urlCurrent = this.location.path();
        let checkLogin = this.isLoginRequired(urlCurrent);
        if (checkLogin) {
          if (!customer) {
            return this.router.navigate(['/pages/login']);
          }
        }
      }
    public onCheckLogin(checkUrl?: boolean){
        let result : boolean = false;
        let _idLogin: string = null;
        this.store.select(state => state.authen._id)
        .pipe(takeUntil(this.destroy.destroySub$))
        .subscribe(_id => {
          if (!_id) {
            if(checkUrl) this.checkUrlLogin(null);
            return result = false;
          } 
          _idLogin = _id;
          this.onLoadCustomerLogin(_id);
        })
        this.store.select(state => state.customer.customerLogin)
        .pipe(takeUntil(this.destroy.destroySub$))
        .subscribe((customer: Customers) => {
          if (!customer || Object.keys(customer).length === 0) {
            if(_idLogin) {
              this.store.dispatch(clearCart());
              this.productService.resetCart();
            }
            return result = false
          };
          if(checkUrl) {
            this.checkUrlLogin(customer);
          }
          return result = true
        })
        return result;
      }
    // public checkLogin() {

    //     this.store.select(state => state.customer.customerLogin)
    //     .pipe(takeUntil(this.destroy.destroySub$))
    //     .subscribe((customer: Customers) => {
    //       if (!customer || Object.keys(customer).length === 0)  return false;
    //       return true;
    //     })

    // }
}
