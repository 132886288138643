import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { createAction, props } from '@ngrx/store';
export const CartActions = {
    onLoadCartList: '[Cart] list cart',
    addToCart: '[Cart] add to cart',
    addToCartSuccess:  '[Cart] add to cart Success',
    onUpdateCart: '[Cart] update cart',
    onUpdateCartSuccess: '[Cart] update cart Success',
    onDeleteCartItem: '[Cart] delete cart item',
    onDeleteCartItemSuccess: '[Cart] delete cart item Success',
    clearCart: '[Cart] clear cart',
  };
  
    
  export const onLoadCartList = createAction(CartActions.onLoadCartList,
    props<{carts: ProductItems[]}>()
  );
      
  export const addToCart = createAction(CartActions.addToCart,
    props<{customerId: string, productItem: ProductItems[], resetCart?: boolean}>()
  );
  export const addToCartSuccess = createAction(CartActions.addToCartSuccess,
    props<{carts: ProductItems[]}>()
  ); 
  export const onUpdateCart = createAction(CartActions.onUpdateCart,
    props<{customerId: string, productItem: ProductItems}>()
  );
  export const onUpdateCartSuccess = createAction(CartActions.onUpdateCartSuccess,
    props<{carts: ProductItems[]}>()
  ); 
  export const onDeleteCartItem = createAction(CartActions.onDeleteCartItem,
    props<{customerId: string, _id: string}>()
  );
  export const onDeleteCartItemSuccess = createAction(CartActions.onDeleteCartItemSuccess,
    props<{carts: ProductItems[]}>()
  ); 
  export const clearCart = createAction(CartActions.clearCart); 