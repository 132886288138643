
import AppConfiguration from '@pickdi/common/lib/utilities/AppConfig';
import { PosConfigOptions } from './PosConfigOptions';
export class PosConfig {

    /**
     * Init Configuration for BOOKBOOK UI
     * @param environment evironment data
     */
    static init(environment: any) {
        // Init Config
        AppConfiguration.LoadConfiguration(PosConfigOptions, environment);
       // AppConfiguration.LoadAttachments(PosUIManager);
        try {
            AppConfiguration.LoadAttachments(null);
        }
        catch(ex){}
    }

}







