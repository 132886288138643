import AppConfiguration  from '@pickdi/common/lib/utilities/AppConfig';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Customers } from '@pickdi/common/lib/model/Customers';
import  CustomersManager  from '@pickdi/common/lib/model/manager/CustomersManager';
import { Injectable } from '@angular/core';
import HttpStatus from '@pickdi/common/lib/base/HttpStatus';
import { AuthenState, HandleError } from '../entities/state.entity';
import { JwtHelperService } from '@auth0/angular-jwt';
import { RequestException } from '../entities/error.entity';
import { ObservableInput, catchError, of, tap } from 'rxjs';
import { loadUnAuthen } from '../actions/auth.action';
import { TypedAction } from '@ngrx/store/src/models';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
@Injectable()
export class AuthenService {
  constructor(
    private toastrService: ToastrService,
    private loadingBar: LoadingBarService,
    private router : Router,
    private http: HttpClient
  ) {
  }
  async register(customers): Promise<HttpStatus<Customers>> {
    localStorage.removeItem('id_token');
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/customer';
    customersManager.classInfo.endPoint = customersManager.classInfo.endPoint.concat('/registerAccount');
    return customersManager.insert(customers);
  }
  async confirmOtpVerification(customers): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/customer';
    customersManager.classInfo.endPoint = customersManager.classInfo.endPoint.concat('/confirmRegister');
    return customersManager.insert(customers);
  }
  async getOtpVerification(customers): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/customer';
    customersManager.classInfo.endPoint = customersManager.classInfo.endPoint.concat('/getOTPConfirm');
    return customersManager.insert(customers);
  }
  async login(customers): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/customer';
    customersManager.classInfo.endPoint = customersManager.classInfo.endPoint.concat('/login');
    return customersManager.insert(customers);

  }
  loadAuth(): AuthenState {
    let token: string;
    let result: AuthenState = { logged: false};
    token = localStorage.getItem('id_token');
    if (localStorage.getItem('id_token')) {
      const jwtHelper = new JwtHelperService();
      try {
        if (jwtHelper.isTokenExpired(token)) {
          localStorage.removeItem('id_token');
        } else {
          const decodedToken = jwtHelper.decodeToken(token);
        //  result.staffCurrent = decodedToken;
          result.logged = true;
          result._id = decodedToken._id;
        }
      } catch (e) { }
    }
      return result;
    } 
    getData(endPoint, action): Promise<any> {
      this.loadingBar.start();
      let headers ;
      if(localStorage.getItem('id_token')) {
        headers = {
          'Authorization': "Bearer " + localStorage.getItem('id_token'),
          'My-Custom-Header': 'foobar',
          'x-wfg-token': localStorage.getItem('id_token')
        };
      }else {
        headers = {
          'My-Custom-Header': 'foobar',
        }
      }
      let host = AppConfiguration.GetConfiguration(AppConfiguration.REST_API_HOST);
      let uri = host + "/" + endPoint
      return new Promise<any>((resolve, reject) => {
        this.http.post<any>(uri, action, { headers }).subscribe(
          (response) => {
            resolve(response);
          },
          (err) => {
            reject(err);
          }
        );
      });
    }
    handleAuthError<T>(error: RequestException, other?: ObservableInput<HandleError & TypedAction<string>>): ObservableInput<HandleError & TypedAction<string>> {
      if (error.code === 43 || error.code === 400 || error.code === 401) {
         //   this._fuseProgressBarService.removeAllLoading();
            // this.router.navigate(['pages/login']);
            return of(loadUnAuthen(error));
        }
        return other;
    }   
}
