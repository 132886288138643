<div class="img-wrapper">
	<div class="lable-block">
		<span class="lable3" >new</span>
		<!-- <span class="lable4" *ngIf='product.sale'>on sale</span> -->
	</div>
	<div class="front">
        <a>
            <img [defaultImage]="product.image ? product.image : 'https://tea-3.lozi.vn/v1/images/resized/banh-mi-doner-kebab-bo-gia-1648620729'" 
                 [lazyLoad]="product.image" 
                 style="width: 100%;
                 height: 250px;"
                 class="img-fluid lazy-loading" alt="">
        </a>
	</div>
	<div class="cart-box">
        <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="ti-reload" aria-hidden="true"></i>
        </a>
    </div>
</div>
<div class="product-detail text-center ">
	<div class="rating">
		<bar-rating [rate]="5" [readOnly]="true" class="text-center pro-rating"></bar-rating>
	</div>
    <a>
        <h6>{{ product.productName | titlecase }}</h6>
    </a>
    <h6>
        {{ product?.prices[0].price | currency:'VND' }}
        <del *ngIf="product?.discount"><span class="money"> {{ product?.price * currency?.price | currency:currency?.currency:'symbol' }}</span></del>
    </h6>
    <!-- <ul class="color-variant" *ngIf="Color(product?.variants).length">
      <li [class]="color" *ngFor="let color of Color(product?.variants)" [ngStyle]="{'background-color': color}"
        (click)="ChangeVariants(color, product)">
      </li>
    </ul> -->
</div>

<app-quick-view #quickView [product]="product" [slug]="product.shopSlug" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<app-confirm-modal #confirmModal></app-confirm-modal>