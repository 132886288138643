import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { takeUntil } from 'rxjs';
@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit {
  @Input() customerId: string;
  @Input() product: ProductItems;
  @Input() isProductNew: boolean;
  productTag :any = productTag;
  @Input() isProductSale: boolean;
  productSelectAddToCart: ProductItems;
  @ViewChild("confirmModal") ConfirmModal: ConfirmModalComponent;
  
  constructor(private productService: ProductService,private destroy: DestroySubsribeService, private route: ActivatedRoute, private toastrService: ToastrService,private store: Store<RootState>, private loadingBar: LoadingBarService, private router: Router) { 

  }
  
  ngOnInit(): void {
      
  }
  async resetCart() {
    const status = await this.productService.resetCart();
    return status
}
checkTags(tagToCheck: string, product: ProductItems): boolean {
  if(!product.productTag) return false
  return product?.productTag.includes(tagToCheck);
}
  ngAfterViewInit() {
    this.ConfirmModal.resultConfirm.subscribe((result) => {
      if (result) {
          if (this.resetCart()) {
            return this.addToCart(this.productSelectAddToCart, true);
          } else {
            return this.toastrService.info('Cài lại giỏ hàng không thành công');
          }
      }
    });
  }
  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }
  async addToCart(product: any, resetCart?: boolean) {
    if (localStorage.getItem('shopSlug')) {
     if (JSON.parse(localStorage['shopSlug']) != product.shopSlug) {
        this.ConfirmModal.openModal('Chọn cửa hàng khác sẽ cài lại giỏ hàng!');
        this.productSelectAddToCart = product;
        return
      } 
    }
    if (!product || !product?.productName || !product?._id) {
      return this.toastrService.error('Vui lòng thử lại')
    }
    this.loadingBar.start();    
    const status = await this.productService.addToCart(product, this.customerId, product.shopSlug, resetCart);
    if(!status)  return 
    this.toastrService.success(`Đã thêm <b >${product?.productName && product.productName.substring(0, 15)+'...'}</b> vào giỏ hàng`)
  }
}
export const productTag = {
  sale1: 'Khuyễn Mãi',
  sale2: 'Khuyến mãi',
  new: 'Món Mới'
}