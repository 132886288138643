<!--footer section -->
<footer [class]="class">
	<div class="light-layout" *ngIf="newsletter">
		<div class="container">
			<section class="small-section border-section border-top-0">
				<div class="row">
					<div class="col-lg-6">
						<div class="subscribe">
							<div>
								<h4>BẠN LÀ NGƯỜI ĐẦU TIÊN</h4>
								<p>Không bao giờ bỏ lỡ bất cứ điều gì từ Pickdi bằng cách đăng ký nhận bản tin của chúng tôi. </p>
							</div>
						</div>
					</div>
					<div class="col-lg-6">
						<form class="form-inline subscribe-form" action="https://pixelstrap.us19.list-manage.com/subscribe/post?u=5a128856334b598b395f1fc9b&amp;id=082f74cbda" target="_blank" method="post">
							<div class="form-group mx-sm-3">
								<input type="email" #name name="EMAIL" id="mce-EMAIL" class="form-control" placeholder="Nhập Email của bạn tại đây">
							</div>
							<button type="submit" class="btn btn-solid">Gửi</button>
						</form>
					</div>
				</div>
			</section>
		</div>
	</div>
	<section class="section-b-space light-layout">
		<div class="container">
			<div class="row footer-theme partition-f">
				<div class="col-lg-4 col-md-6">
					<div class="footer-title footer-mobile-title">
						<h4>about</h4>
					</div>
					<div class="footer-contant">
						<div class="footer-logo">
							<img [src]="themeLogo" alt="logo">
						</div>
						<p>Pickdi với sứ mệnh giảm thời gian chi phí cho chủ cửa hàng cũng như khách hàng lĩnh vực F&B. Đem đến nhiều trải nghiệm hài lòng nhưng với chi phí thấp.</p>
						<div class="footer-social">
                            <!-- <ul>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-google-plus" aria-hidden="true"></i></a>                 
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-instagram" aria-hidden="true"></i></a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)"><i class="fa fa-rss" aria-hidden="true"></i></a>
                                </li>
                            </ul> -->
                        </div>
					</div>
				</div>
				<div class="col offset-xl-1 service-footer">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Dịch vụ</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Quản lý cửa hàng</a></li>
                                <li><a href="javascript:void(0)">Thiết bị</a></li>
                                <li><a href="javascript:void(0)">Đối tác</a></li>
                                <li><a href="javascript:void(0)">Cộng tác viên</a></li>
								<li><a href="javascript:void(0)">Nhà đầu tư</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col about-me">
					<div class="sub-title">
						<div class="footer-title">
                            <h4>Về chúng tôi</h4>
                        </div>
                        <div class="footer-contant">
                            <ul>
                                <li><a href="javascript:void(0)">Giới thiệu</a></li>
                                <li><a href="javascript:void(0)">Chính sách</a></li>
                                <li><a href="javascript:void(0)">Liên hệ</a></li>
                            </ul>
                        </div>
					</div>
				</div>
				<div class="col info-fwg">
					<div class="sub-title">
						<div class="footer-title">
						    <h4>Thông tin</h4>
						</div>
						<div class="footer-contant">
						    <ul class="contact-list">
						        <li><i class="fa fa-map-marker"></i>Pickdi Platform, 04 - 06 Vũ Lập, Liên Chiểu TP Đà Nẵng</li>
						        <li><i class="fa fa-phone"></i>Hotline: 0925 911 777</li>
						        <li class="d-flex"><i class="fa fa-envelope-o "></i><span >Email: </span><a class="text-lowercase">&nbsp;fastworldcompany@gmail.com</a></li>
						    </ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
	<div class="sub-footer">
		<div class="container">
			<div class="row">
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="footer-end">
                    <p><i class="fa fa-copyright" aria-hidden="true"></i> 2023 - Bản quyền thuộc về Pickdi</p>
                  </div>
                </div>
                <div class="col-xl-6 col-md-6 col-sm-12">
                  <div class="payment-card-bottom">
                    <ul>
                      <li>
                        <a><i class="fa fa-facebook" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a><i class="fa fa-twitter" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a><i class="fa fa-instagram" aria-hidden="true"></i></a>
                      </li>
                      <li>
                        <a><i class="fa fa-rss" aria-hidden="true"></i></a>
                      </li>
                    </ul>
                  </div>
                </div>
            </div>
		</div>
	</div>
</footer>
<!--footer section end -->