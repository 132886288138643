import ProductItems from '@pickdi/common/lib/model/ProductItems';
import ProductItemsManager  from '@pickdi/common/lib/model/manager/ProductItemsManager'
import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Injectable } from '@angular/core';


@Injectable()
export class ProductService {
    constructor(){}

    async onLoadAllProduct(filter): Promise<HttpStatus<ProductItems>> {
        const menuManager = new ProductItemsManager(null);
        menuManager.classInfo.endPoint = "eCommerce";
        menuManager.classInfo.endPoint = menuManager.classInfo.endPoint.concat('/getAllProduct');
        return menuManager.search(filter);
    }
    async onLoadAllPromotionProduct(filter): Promise<HttpStatus<ProductItems>>{
        const menuManager = new ProductItemsManager(null);
        menuManager.classInfo.endPoint = "eCommerce";
        menuManager.classInfo.endPoint = menuManager.classInfo.endPoint.concat('/getAllProduct');
        return menuManager.search(filter);
    }
}