import { Action, on } from "@ngrx/store";
import { createReducer } from "../utils/store.util";
import { onLoadInfoShopBySlug, onLoadInfoShopBySlugSuccess , onLoadAllShop , onLoadAllShopSuccess, onLoadAllVoucherShop, onLoadAllVoucherShopSuccess, clearShopDetail, onFilterProductOrShop, onFilterProductOrShopSuccess  } from "../actions/shop.action";
import { ShopsState } from "../entities/state.entity";
import { state } from "@angular/animations";

const initialState : ShopsState = {
    shopItem: null,
    listShop : [],
    listSearchShopOrProduct: [],
    voucherList: null,
   };
   
   export const reducer = createReducer(
     initialState,
    on(onLoadInfoShopBySlug, (state, action) => ({
         ...state,
       })),
    on(onLoadInfoShopBySlugSuccess, (state, action) => ({
        ...state,
        shopItem: action,
    })),
    on(onLoadAllVoucherShop, (state, action) => ({
      ...state,
    })),
    on(onLoadAllVoucherShopSuccess, (state, action) => ({
        ...state,
        voucherList: action?.voucherList,
    })),
    on(onLoadAllShop ,(state , action)=>({
      ...state
    })),
    on(onLoadAllShopSuccess , (state , action) =>({
      ...state,
      listShop : action
    })),
    on(onFilterProductOrShop ,(state , action)=>({
      ...state
    })),
    on(onFilterProductOrShopSuccess , (state , action) =>({
      ...state,
      listSearchShopOrProduct : action.shops
    })),
    on(clearShopDetail , (state , action) =>({
      ...state,
      shopItem : null,
      voucherList : null
    })),
   );
   export function shopsReducer(state: ShopsState | undefined, action: Action) {
     return reducer(state, action);
   }