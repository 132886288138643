import Constants from "@pickdi/common/lib/base/Constants";
import { Customers } from "@pickdi/common/lib/model/Customers";
import { ProductItems } from "@pickdi/common/lib/model/ProductItems";
import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
  Input,
  Injectable,
  PLATFORM_ID,
  Inject,
} from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { Product } from "../../../classes/product";
import { ProductService, state } from "../../../services/product.service";
import { ToastrService } from "ngx-toastr";
import { LoadingBarService } from "@ngx-loading-bar/core";
import {
  regexFormEmail,
  regexFormPattern,
  regexFormTel,
  scrollToFirstInvalidControl,
  validateAllFormFields,
  validateForm,
} from "src/app/shared/functions/function-helper";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DestroySubsribeService } from "src/app/shared/services/destroySubscribe.service";
import { RootState } from "src/app/store/entities/state.entity";
import { Store } from "@ngrx/store";
import { takeUntil } from "rxjs";
import { login, register } from "src/app/store/actions/auth.action";
import { OtpModalComponent } from "../otp-modal/otp-modal.component";
import { addToCart } from "src/app/store/actions/cart.action";
import { ConfirmModalComponent } from "../confirm-modal/confirm-modal.component";

@Component({
  selector: "app-register-customer-modal",
  templateUrl: "./register-customer-modal.component.html",
  styleUrls: ["./register-customer-modal.component.scss"],
})
export class RegisterCustomerModalComponent implements OnInit, OnDestroy {
  @Input() product: ProductItems;
  @Input() currency: any;
  telPattern: string = "^0.*";
  validateForm = validateForm;
  regexFormPattern = regexFormPattern;
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  dataRegister: Customers;
  @ViewChild("otpModal") OtpModal: OtpModalComponent;
  @ViewChild("confirmCart") confirmCart: ConfirmModalComponent;
  checkCustomerNew: boolean = false;
  @ViewChild("registerCustomerModal", { static: false })
  registerCustomerModal: TemplateRef<any>;

  customerCurrent: Customers;
  public closeResult: string;
  public ImageSrc: string;
  public modalOpen: boolean = false;
  navigate: string
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private modalService: NgbModal,
    public productService: ProductService,
    private formBuilder: FormBuilder,
    private destroy: DestroySubsribeService,
    private store: Store<RootState>,
    private toastrService: ToastrService,
    private loadingBar: LoadingBarService
  ) {}

  ngOnInit(): void {
    this.loginForm = this.createLoginForm();
    this.registerForm = this.createRegisterForm();
    this.store
      .select((state) => state.authen.errorRegister)
      .pipe(takeUntil(this.destroy.destroySub$))
      .subscribe((errorRegister) => {
        if (!errorRegister || !errorRegister?.message) {
          if (this.dataRegister?.tel && this.checkCustomerNew) {
            this.OtpModal.openModal(this.dataRegister.tel , '/pages/checkout');
            return;
          }
          return;
        }
        if (errorRegister?.message == Constants.CUSTOMERS.USER_NOT_CONFIRM) {
          this.dataRegister?.tel
            ? this.OtpModal.openModal(this.dataRegister.tel, '/pages/checkout')
            : "";
        } 
      });
   
  }
  handleUpdateShopSlug() {
    if(this.customerCurrent.cart.length > 0) localStorage.setItem("cartItems", JSON.stringify(this.customerCurrent.cart));
    state.cart = this.customerCurrent.cart;
    if(this.customerCurrent?.cart[0]?.shopId) localStorage.setItem("shopId", JSON.stringify(this.customerCurrent?.cart[0]?.shopId));
    if(this.customerCurrent?.cart[0]?.shopSlug) localStorage.setItem("shopSlug", JSON.stringify(this.customerCurrent?.cart[0]?.shopSlug));
  }
  handleAddProductToAccount(customerCurrent: Customers) {
    let productLocal;
    if(localStorage.getItem('cartItems') && JSON.parse(localStorage['cartItems']).length > 0) {
        productLocal = JSON.parse(localStorage['cartItems']);
        productLocal.map((product) => {
            product.totalMoney = product.priceDefault * product.quantity;
        });
    }
    if (productLocal) {    
        if(localStorage.getItem('shopId') && JSON.parse(localStorage['shopId'])) {
          if(customerCurrent.cart.length > 0 && JSON.parse(localStorage['shopId']) != customerCurrent.cart[0].shopId)  {
            this.confirmCart.openModal('Xác nhận cài lại giỏ hàng mới');
          } else {
            this.store.dispatch(addToCart({customerId: customerCurrent._id , productItem: productLocal, resetCart: false}));
            this.navigate ? this.router.navigate([this.navigate]) : '';
            this.handleUpdateShopSlug();
          }
        }
        
    } else {
      this.handleUpdateShopSlug();
      this.navigate ? this.router.navigate([this.navigate]) : '';
    }
    
}
ngAfterViewInit() {
  this.confirmCart.resultConfirm.subscribe((result) => {
    if (result) {
      let productLocal;
      if(localStorage.getItem('cartItems') && JSON.parse(localStorage['cartItems']).length > 0) {
        productLocal = JSON.parse(localStorage['cartItems']);
        productLocal.map((product) => {
            product.totalMoney = product.priceDefault * product.quantity;
        });
      }
      this.store.dispatch(addToCart({customerId: this.customerCurrent._id , productItem: productLocal, resetCart: true}));
      this.toastrService.info('Sản phẩm lấy từ giỏ hàng mới');
    } else {
      localStorage.setItem("cartItems", JSON.stringify(this.customerCurrent.cart));
    }
    this.handleUpdateShopSlug();
    this.navigate ? this.router.navigate([this.navigate]) : '';
  });
}
  handleCreateAccount() {
    if (this.registerForm.valid) {
      // alert('call api để xác nhận mã otp');
      let dataRegister = this.registerForm.value;
      dataRegister.fullName = dataRegister.fullName.trim();
      dataRegister.tel = dataRegister.tel.replaceAll(" ", "");
      this.dataRegister = dataRegister;
      this.store.dispatch(register(dataRegister));
      
    } else {
      validateAllFormFields(this.registerForm);
      scrollToFirstInvalidControl(this.registerForm);
    }
  }
  createRegisterForm(): FormGroup {
    return this.formBuilder.group({
      fullName: ["", [Validators.maxLength(35), Validators.required]],
      tel: ["", [Validators.required, regexFormTel]],
    });
  }

  // login ---------------------------
  createLoginForm(): FormGroup {
    return this.formBuilder.group({
      tel: ["", [Validators.required, regexFormTel]],
      password: [
        "",
        [
          Validators.minLength(6),
          Validators.maxLength(32),
          Validators.required,
        ],
      ],
    });
  }
  handleCheckNewCustomer(event) {
    this.loginForm = this.createLoginForm();
    this.registerForm = this.createRegisterForm();
    this.checkCustomerNew = event.target.checked;
  }
  handleSubmit() {
    if (this.checkCustomerNew) {
      this.handleCreateAccount();
      
    }else {
      this.handleLogin();
    }
    this.store
    .select((state) => state.authen.customerCurrent)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe((customerCurrent) => {
      if (!customerCurrent) return;
      if (!this.checkCustomerNew) {
        let timeout = setTimeout(() => {
          // this.navigate ? this.router.navigate([this.navigate]) : '';
          this.modalOpen = false;
          this.modalService.dismissAll();
          this.customerCurrent = customerCurrent;
          if (!this.checkCustomerNew) {
            this.handleAddProductToAccount(customerCurrent);
          };
          clearTimeout(timeout);
        }, 500);
        return
      } 
    });
  }
  handleLogin() {
    if (this.loginForm.valid) {
      let datalogin = this.loginForm.value;
      datalogin.tel = datalogin.tel.replaceAll(" ", "");
      // this.toastrService.show('ahihi')
      this.store.dispatch(login(datalogin));
    } else {
      validateAllFormFields(this.loginForm);
      scrollToFirstInvalidControl(this.loginForm);
    }
   
  }
  openModal(navigate?: string) {
    this.modalOpen = true;
    this.navigate = navigate;
    if (isPlatformBrowser(this.platformId)) {
      // For SSR
      this.modalService
        .open(this.registerCustomerModal, {
          size: "lg",
          ariaLabelledBy: "modal-basic-title",
          centered: true,
          windowClass: "registerCustomerModal",
        })
        .result.then(
          (result) => {
            `Result ${result}`;
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if (this.modalOpen) {
      this.modalService.dismissAll();
    }
  }
}
