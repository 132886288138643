import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ConfirmModalComponent } from '../../modal/confirm-modal/confirm-modal.component';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';

@Component({
  selector: 'app-product-box-three',
  templateUrl: './product-box-three.component.html',
  styleUrls: ['./product-box-three.component.scss']
})
export class ProductBoxThreeComponent implements OnInit, AfterViewInit {

  @Input() product: any;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() cartModal: boolean = false; // Default False
  productSelectAddToCart: ProductItems;
  customerId: string;
  @ViewChild("confirmModal") ConfirmModal: ConfirmModalComponent;
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  constructor(private toastrService: ToastrService, private store: Store<RootState>, private destroy: DestroySubsribeService, private loadingBar: LoadingBarService,private productService: ProductService) { }

  ngOnInit(): void {
    this.onLoadCustomerLogin();
  }
  onLoadCustomerLogin() {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.customerId = null;
      this.customerId = customerLogin._id;
    })
  }
  async addToCart(product: any, resetCart?: boolean) {
    this.productSelectAddToCart = null;
    if (localStorage.getItem('shopSlug')) {
     if (JSON.parse(localStorage['shopSlug']) != product.shopSlug) {
        this.ConfirmModal.openModal('Chọn cửa hàng khác sẽ cài lại giỏ hàng!');
        this.productSelectAddToCart = product;
        return
      } 
    }
    if (!product || !product?.productName || !product?._id) {
      return this.toastrService.error('Vui lòng thử lại')
    }
    this.loadingBar.start();    
    const status = await this.productService.addToCart(product, this.customerId, product.shopSlug, resetCart);
    if(!status)  return 
    this.toastrService.success(`Đã thêm <b >${product?.productName && product.productName.substring(0, 15)+'...'}</b> vào giỏ hàng`)
  }
  async resetCart() {
    const status = await this.productService.resetCart();
    return status
}
  ngAfterViewInit() {
    this.ConfirmModal.resultConfirm.subscribe((result) => {
      if (result) {
          if (this.resetCart()) {
            return this.addToCart(this.productSelectAddToCart, true);
          } else {
            return this.toastrService.info('Cài lại giỏ hàng không thành công');
          }
      }
    });
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

}
