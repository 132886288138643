<ng-template class="theme-modal" #quickView let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body">
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="row">
                <div class="col-lg-6  col-xs-12">
                    <div class="quick-view-img">
                        <img [src]="product?.image ? product?.image : 'assets/images/product/placeholder.jpg'" onerror="this.src = 'assets/images/product/placeholder.jpg'" [alt]="'Hình ảnh sản phẩm'" class="img-fluid w-100 rounded">
                    </div>
                </div>
                <div class="col-lg-6 rtl-text">
                    <div class="product-right">
                        <h2>{{ product?.productName | titlecase }}</h2>
                        <h3  *ngFor="let price of product?.prices">
                            {{price?.name}} : {{ price?.price | currency:'VND':'symbol':null:'vi-VN'}} </h3>
                            <del *ngIf="product?.priceDefault"><span class="money"> {{ product?.priceDefault | currency:'VND':'symbol':null:'vi-VN' }}</span></del>
                        <!-- <ul class="color-variant" *ngIf="Color(product.variants).length">
                            <li [class]="color" *ngFor="let color of Color(product.variants)"
                                [ngStyle]="{'background-color': color}" (click)="ChangeVariants(color, product)">
                            </li>
                        </ul> -->
                        <div class="border-product" *ngIf="product?.description">
                            <h6 class="product-title">Mô tả sản phẩm</h6>
                            <p>{{ product.description.substring(0, 200)+'...' }}</p>
                        </div>
                        <div class="product-description border-product">
                            <!-- <div class="size-box" *ngIf="Size(product.variants).length">
                                <ul>
                                    <li *ngFor="let size of Size(product.variants)">
                                        <a href="javascript:void(0)">{{ size | titlecase }}</a>
                                    </li>
                                </ul>
                            </div> -->
                            <!-- <h5 class="avalibility" *ngIf="counter <= product.stock"><span>In Stock</span></h5>
                            <h5 class="avalibility" *ngIf="counter > product.stock"><span>Out of Stock</span></h5> -->
                            <h6 class="product-title">Số lượng</h6>
                            <div class="qty-box">
                                <div class="input-group">
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-left-minus" data-type="minus" (click)="decrement()">
                                            <i class="ti-angle-left"></i>
                                        </button>
                                    </span>
                                    <input type="text" name="quantity" class="form-control input-number"  [value]="counter" disabled>
                                    <span class="input-group-prepend">
                                        <button type="button" class="btn quantity-right-plus" data-type="plus" (click)="increment()">
                                            <i class="ti-angle-right"></i>
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="product-buttons">
                            <a href="javascript:void(0)" class="btn btn-solid" [class.disabled]="counter > product.stock" (click)="addToCart(product)">Thêm giỏ hàng</a>
                            <!-- chi tiết sản phẩm -->
                            <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]" class="btn btn-solid">view detail</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<app-confirm-modal #confirmModal></app-confirm-modal>