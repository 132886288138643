<ng-template #qrModal let-modal>
    <div class="modal-content">
        <div class="modal-header">
            <h4>{{title}}</h4>
            <button type="button" class="close float-end border-0" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <img 
          [src]="img ? img : 'assets/images/product/placeholder.jpg'" 
          onerror="this.src = 'assets/images/product/placeholder.jpg'"
          class="img-fluid lazy-loading" 
          alt="" />
        </div>
        <div class="modal-footer">
            <button class="btn btn-solid" *ngIf="isDownload" (click)="handleDownloadImage()">{{titleBtn}}</button>
        </div>
    </div>
</ng-template>
