import { WebOrders } from '@pickdi/common/lib/model/WebOrders';
import { Tables } from '@pickdi/common/lib/model/Tables';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError } from 'rxjs/operators';
import Orders from '@pickdi/common/lib/model/Orders';
import { Action } from '@ngrx/store';
import { AuthenService } from '../services/authen.service';
import { of, from } from 'rxjs';
import { OrdersAction, onClearOrder, onCreateOrderSuccess, onLoadOrderListSuccess, onLoadTableByShopSuccess } from '../actions/order.action';
import { OrdersService } from '../services/order.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { hiddenLoadding } from '../actions/loadding.action';
@Injectable()
export class OrderEffect {
    constructor(
        private actions$: Actions,
        private ordersService : OrdersService,
        private authenService: AuthenService,
        private loadingBar: LoadingBarService
    ) {
    }

   

    private createOrder$ = createEffect(() => this.actions$.pipe(
        ofType(OrdersAction.onCreateOrder),
        mergeMap((action: Action & Orders) => from(this.ordersService.onCreateOrder(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    if(value.entity) value.entity.tableSelect = action?.tableSelect
                    return [
                        value.entity ? onCreateOrderSuccess(value.entity) : onClearOrder(),
                    ]
                }),
                catchError(err =>
                    {
                        return [
                            this.authenService.handleAuthError(err),
                            onClearOrder(),
                        ]
                    }
                )
            )
        )
    ));


    private onLoadOderList$ = createEffect(() => this.actions$.pipe(
        ofType(OrdersAction.onLoadOrderList),
        mergeMap((action) => from(this.ordersService.onLoadOrderList(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    return [
                        onLoadOrderListSuccess({oders: value?.entity?.webOrderData}),
                        hiddenLoadding()
                    ]
                }),
                catchError(err =>
                    {
                        this.loadingBar.stop();
                        return [
                            this.authenService.handleAuthError(err),
                            onClearOrder(),
                            hiddenLoadding()
                        ]
                    }
                )
            )
        )
    ));

    private onLoadTableByShop$ = createEffect(() => this.actions$.pipe(
        ofType(OrdersAction.onLoadTableByShop),
        mergeMap((action: Action & Tables) => from(this.ordersService.onLoadTableByShop(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    return [
                        onLoadTableByShopSuccess(value.entity),
                    ]
                }),
                catchError(err =>
                    (
                        this.authenService.handleAuthError(err, of())
                    )
                )
            )
        )
    ));

}