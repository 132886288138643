export enum PAYMENT_METHOD {
    other = 'Khác',
    cash = 'Tiền mặt',
    transfer = 'Chuyển khoản'
  }

  export enum STATUS_ORDER {
    wait = 'Đang chờ',
    received = 'Đã nhận đơn',
    inProcessKichen = 'Bếp đang thực hiện',
    kichenComplete = 'Bếp hoàn thành',
    complete = 'Hoàn thành',
    orderCancel = 'Đã hủy'
  }