import MenusManager from '@pickdi/common/lib/model/manager/MenusManager';
import  Menus from '@pickdi/common/lib/model/Menus';
import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
@Injectable()
export class MenusService {
  constructor(
    private loadingBar: LoadingBarService,
    ) { }
  async onLoadMenusByShop(actionId): Promise<HttpStatus<Menus>>  {
    const menusManager = new MenusManager(null);
    menusManager.classInfo.endPoint = 'eCommerce/shop';
    menusManager.classInfo.endPoint = menusManager.classInfo.endPoint.concat('/getAllProductItemMenu');
    let slug = actionId.shopSlug ? actionId.shopSlug : actionId.slug;
    return menusManager.get(slug);
  }

}