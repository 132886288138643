<div class="form-group e-commerce-input">
    <label [for]="formName" class="btn-default-font" *ngIf="label">
      <!-- <span class="star-color" *ngIf="castedRequired">* </span> -->
      {{ label }}</label>
    <div>
      <ng-container [ngSwitch]="inputType">
        <!--Input phone number-->
        <ng-container *ngSwitchCase="'phoneNumber'">
          <input
            class="form-control"
            [id]="formName"
            [type]="typeInput"
            [name]="formName"
            [pattern]="pattern"
            [formControlName]="formName"
            [ngClass]="{ 'input-danger': castedInputRequired }"
            [attr.disabled]="castedDisabled ? true : null"
            [autocomplete]="autoComplete"
            [placeholder]="placeholder"
            onkeydown="return event.keyCode !== 69"
            (input)="onChangeVal($event)"
          />
        </ng-container>
  
        <!--default Input-->
        <ng-container *ngSwitchDefault>
          <input
            class="form-control"
            [id]="formName"
            [name]="formName"
            [pattern]="pattern"
            [type]="typeInput"
            [formControlName]="formName"
            [ngClass]="{ 'input-danger': castedInputRequired }"
            [attr.disabled]="castedDisabled ? true : null"
            [autocomplete]="autoComplete"
            [placeholder]="placeholder"
            (input)="onChangeVal($event)"
          />
        </ng-container>
      </ng-container>
      <div *ngIf="castedInputRequired && label">
        <p class="text-danger" *ngIf="castedInputValid">Nhập {{ label }}</p>
      </div>
      <ng-content select=".regexForm"></ng-content>
      <ng-content select=".duplicate"></ng-content>
    </div>
</div>
  