import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    // this.navServices.items.subscribe((menuItems) =>{this.menuItems = menuItems});
    this.menuItems = [
      {
        active: false,
        title : 'Trang Chủ',
        type : 'sub'
      },
      {
        active: false,
        title : 'Quán Mới',
        type : 'sub',
      },
      {
        active : false,
        title : 'Ưu Đãi',
        type : 'sub',
      },
      {
        active : false ,
        title : 'Thương hiệu quen thuộc',
        type : 'sub',
      },
      {
        active : false,
        title : 'Tin Tức',
        type : 'sub'
      }
    ]
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {

  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

}
