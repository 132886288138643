import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, catchError } from 'rxjs/operators';
import { Action, Store } from '@ngrx/store';
import Shops from '@pickdi/common/lib/model/Shops';
import { AuthenService } from '../services/authen.service';
import { of, from } from 'rxjs';
import { ShopActions, onLoadInfoShopBySlugSuccess , onLoadAllShopSuccess, onLoadAllVoucherShopSuccess, onFilterProductOrShopSuccess } from '../actions/shop.action';
import { ShopsService } from '../services/shop.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import { MenusService } from '../services/menu.service';
import { onLoadMenusByShopSuccess } from '../actions/menu.action';
import { hiddenLoadding } from '../actions/loadding.action';
@Injectable()
export class ShopEffect {

  private onLoadInfoShopBySlug$ = createEffect(() => this.actions$.pipe(
    ofType(ShopActions.onLoadInfoShopBySlug),
    mergeMap((action: Action & Shops) => from(this.shopService.onLoadInfoShopBySlug(action))
      .pipe(
        mergeMap(value => {
          this.loadingBar.stop();
          hiddenLoadding();
          return [
            onLoadInfoShopBySlugSuccess(value.entity),
          ];
        }),
        catchError(err => (
          hiddenLoadding(),
          this.loadingBar.stop(),
          this.toastrService.error(err.message),
          this.authenService.handleAuthError(err, null)
      )
      )
    )
  )
  ));
  private onLoadShopDetail$ = createEffect(() => this.actions$.pipe(
    ofType(ShopActions.onLoadShopDetail),
    mergeMap((action: Action) => from(this.shopService.onLoadInfoShopBySlug(action))
      .pipe(
        mergeMap(shopInfo => from(this.menuService.onLoadMenusByShop(action))
          .pipe(
            mergeMap(menu => from(this.shopService.onLoadAllVoucherShop(action))
              .pipe(
              mergeMap(voucher => {
                this.loadingBar.stop();
                return [
                  onLoadInfoShopBySlugSuccess(shopInfo.entity),
                  onLoadMenusByShopSuccess(menu.entity),
                  onLoadAllVoucherShopSuccess({voucherList: voucher.entity}),
                  hiddenLoadding(),
                ];
              }),
              catchError(err =>
                {
                  this.loadingBar.stop();
                  return [
                    hiddenLoadding(),
                  ]
                })
          )),
        catchError(err =>
          {
            this.loadingBar.stop();
            return [
              hiddenLoadding(),
            ]
          }
        )
      )
    ),
    catchError(err =>
      {
        this.loadingBar.stop();
        return [
          hiddenLoadding()
        ]
      })
  ))));
  private onLoadAllVoucherShop$ = createEffect(() => this.actions$.pipe(
    ofType(ShopActions.onLoadAllVoucherShop),
    mergeMap((action: Action & Shops) => from(this.shopService.onLoadAllVoucherShop(action))
      .pipe(
        mergeMap(value => {
          this.loadingBar.stop();
          return [
            onLoadAllVoucherShopSuccess({voucherList: value.entity}),
          ];
        }),
        catchError(err => (
          this.loadingBar.stop(),
          this.toastrService.error(err.message),
          this.authenService.handleAuthError(err, null)
      )
      )
    )
  )
  ));       
  private onFilterProductOrShop$ = createEffect(() => this.actions$.pipe(
    ofType(ShopActions.onFilterProductOrShop),
    mergeMap((action: Action & Shops) => from(this.shopService.onFilterProductOrShop(action))
      .pipe(
        mergeMap(value => {
          return [
            onFilterProductOrShopSuccess({shops: value.entity}),
            hiddenLoadding(),
          ];
        }),
        catchError(err => (
          hiddenLoadding(),
          this.toastrService.error(err.message),
          this.authenService.handleAuthError(err, null)
      )
      )
    )
  )
  ));   

  private onLoadAllShop$ = createEffect(() => this.actions$.pipe(
    ofType(ShopActions.onLoadAllShop),
    mergeMap((action: Action & Shops) => from(this.shopService.onLoadAllShop(action))
    .pipe(
      mergeMap(value =>{
        return [
          hiddenLoadding(),
          onLoadAllShopSuccess(value.entity)
        ]
      }),
    )
    )
  ))

 


  constructor(
    private actions$: Actions,
    private shopService: ShopsService,
    private authenService: AuthenService,
    private menuService: MenusService,
    private loadingBar: LoadingBarService, private toastrService :ToastrService
  ) { }
}