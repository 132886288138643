import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BarRatingModule } from "ngx-bar-rating";
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { TranslateModule } from '@ngx-translate/core';

// Header and Footer Components
import { HeaderOneComponent } from './header/header-one/header-one.component';
import { FooterOneComponent } from './footer/footer-one/footer-one.component';
import { HeaderTwoComponent } from './header/header-two/header-two.component';
import { FooterTwoComponent } from './footer/footer-two/footer-two.component';
import { HeaderThreeComponent } from './header/header-three/header-three.component';
import { FooterThreeComponent } from './footer/footer-three/footer-three.component';
import { HeaderFourComponent } from './header/header-four/header-four.component';
import { FooterFourComponent } from './footer/footer-four/footer-four.component';

// Components
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { MenuComponent } from './components/menu/menu.component';
import { SettingsComponent } from './components/settings/settings.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { ProductBoxOneComponent } from './components/product/product-box-one/product-box-one.component';
import { ProductBoxTwoComponent } from './components/product/product-box-two/product-box-two.component';
import { ProductBoxThreeComponent } from './components/product/product-box-three/product-box-three.component';
import { ProductBoxFourComponent } from './components/product/product-box-four/product-box-four.component';
import { ProductBoxFiveComponent } from './components/product/product-box-five/product-box-five.component';
import { ProductBoxVerticalComponent } from './components/product/product-box-vertical/product-box-vertical.component';
import { ProductBoxVerticalSliderComponent } from './components/product/product-box-vertical-slider/product-box-vertical-slider.component';

// Modals Components
import { NewsletterComponent } from './components/modal/newsletter/newsletter.component';
import { QuickViewComponent } from './components/modal/quick-view/quick-view.component';
import { CartModalComponent } from './components/modal/cart-modal/cart-modal.component';
import { CartVariationComponent } from './components/modal/cart-variation/cart-variation.component';
import { VideoModalComponent } from './components/modal/video-modal/video-modal.component';
import { SizeModalComponent } from './components/modal/size-modal/size-modal.component';
import { AgeVerificationComponent } from './components/modal/age-verification/age-verification.component';

// Skeleton Loader Components
import { SkeletonProductBoxComponent } from './components/skeleton/skeleton-product-box/skeleton-product-box.component';

// Layout Box
import { LayoutBoxComponent } from './components/layout-box/layout-box.component';

// Tap To Top
import { TapToTopComponent } from './components/tap-to-top/tap-to-top.component';

// Pipes
import { DiscountPipe } from './pipes/discount.pipe';
import { NgOtpInputModule } from 'ng-otp-input';
import { OtpModalComponent } from './components/modal/otp-modal/otp-modal.component';
import { RegisterCustomerModalComponent } from './components/modal/register-customer-modal/register-customer-modal.component';
import { ECommerceInputModule } from './components/e-commerce-input/e-commerce-input.module';
import { SelectTableModalComponent } from './components/modal/select-table-modal/select-table-modal.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ProductBoxComponent } from './components/product/product-box/product-box.component';
import { ConfirmModalComponent } from './components/modal/confirm-modal/confirm-modal.component';
import { LoaddingProductListComponent } from './components/loadding-product-list/loadding-product-list.component';
import { ConfirmPasswordModalComponent } from './components/modal/confirm-password-modal/confirm-password-modal.component';
import { ScanQRModalComponent } from './components/modal/scan-qr-login-modal/scan-qr-login-modal.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { ImageDownloadModalComponent } from './components/modal/download-image-modal/download-image-modal.component';
import { OrderViewDetailComponent } from './components/modal/view-order-detail/view-order-detail.component';
import { ForgotPasswordComponent } from './components/modal/forgot-password/forgot-password.component';
import { OtpLayoutComponent } from './components/otp-layout/otp-layout.component';
import { EvaluateProductComponent } from './components/modal/evaluate-product/evaluate-product.component';
import { ProductCardComponent } from './components/product/product-card/product-card.component';
import { ShopCardComponent } from './components/product/shop-card/shop-card.component';
import { LoaddingShopListComponent } from './components/loadding-shop-list/loadding-shop-list.component';

LOAD_WASM().subscribe()
@NgModule({
  declarations: [
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    LeftMenuComponent,
    MenuComponent,
    SettingsComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    OtpModalComponent,
    RegisterCustomerModalComponent,
    SelectTableModalComponent,
    ProductBoxComponent,
    ConfirmModalComponent,
    LoaddingProductListComponent,
    ConfirmPasswordModalComponent,
    ScanQRModalComponent,
    ImageDownloadModalComponent,
    OrderViewDetailComponent,
    ForgotPasswordComponent,
    OtpLayoutComponent,
    ProductCardComponent,
    ShopCardComponent,
    EvaluateProductComponent,
    ProductCardComponent,
    LoaddingShopListComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    NgOtpInputModule,
    ECommerceInputModule,
    MatTabsModule,
    NgxScannerQrcodeModule 
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
    CarouselModule,
    BarRatingModule,
    LazyLoadImageModule,
    NgxSkeletonLoaderModule,
    TranslateModule,
    HeaderOneComponent,
    FooterOneComponent,
    HeaderTwoComponent,
    FooterTwoComponent,
    HeaderThreeComponent,
    FooterThreeComponent,
    HeaderFourComponent,
    FooterFourComponent,
    BreadcrumbComponent,
    CategoriesComponent,
    ProductBoxOneComponent,
    ProductBoxTwoComponent,
    ProductBoxThreeComponent,
    ProductBoxFourComponent,
    ProductBoxFiveComponent,
    ProductBoxVerticalComponent,
    ProductBoxVerticalSliderComponent,
    NewsletterComponent,
    QuickViewComponent,
    CartModalComponent,
    CartVariationComponent,
    VideoModalComponent,
    SizeModalComponent,
    AgeVerificationComponent,
    SkeletonProductBoxComponent,
    LayoutBoxComponent,
    TapToTopComponent,
    DiscountPipe,
    OtpModalComponent,
    RegisterCustomerModalComponent,
    SelectTableModalComponent,
    ProductBoxComponent,
    ConfirmModalComponent,
    LoaddingProductListComponent,
    ConfirmModalComponent,
    ConfirmPasswordModalComponent,
    ScanQRModalComponent,
    ImageDownloadModalComponent,
    OrderViewDetailComponent,
    ForgotPasswordComponent,
    ProductCardComponent,
    ShopCardComponent,
    EvaluateProductComponent,
    ProductCardComponent,
    LoaddingShopListComponent
  ]
})
export class SharedModule { }
