<div class="d-flex justify-content-center align-items-center flex-column">
  <img src="assets/images/OTP.jpg" class="img-fluid img-otp" alt="" />
  <h3 class="mt-2">Xác minh tài khoản</h3>
  <p class="text-center mb-0">
    Vui lòng nhập vào mã 6 chữ số gửi đến số zalo của bạn
  </p>
  <p class="text-center">{{ tel }}</p>
  <ng-otp-input
    #ngOtpInput
    (onInputChange)="onOtpChange($event)"
    [config]="config"
  ></ng-otp-input>
  <p *ngIf="isInputDisabled">Hết hiệu lực sau <span class="btn-refresh-otp"> {{ formatTime(secondsRemaining) }}</span></p>
  <p class="btn-refresh-otp" *ngIf="!isInputDisabled" (click)="onRefreshOTP()"> lấy mã </p>

  <button class="btn btn-solid rounded" (click)="onSubmitOTP()">
    Xác nhận
  </button>
</div>
