<!--modal popup start-->
<ng-template class="theme-modal" #confirmModal let-modal>
	<div class="modal-content quick-view-modal">
        <div class="modal-body">
            <div class="container-fluid p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content">
                                <h4>{{title}}</h4>
                                <div class="btn-confirm">
                                    <button type="submit" (click)="handleConfirm()" class="btn btn-solid">
                                        Xác nhận
                                    </button>
                                    <button type="submit " (click)="handleNoConfirm()" class="btn btn-solid">
                                        Hủy
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->