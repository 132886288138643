import { Injectable } from '@angular/core'
import { Actions, createEffect, ofType } from '@ngrx/effects'
import { mergeMap, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import ProductItems from '@pickdi/common/lib/model/ProductItems';
import ProductItemsManager  from '@pickdi/common/lib/model/manager/ProductItemsManager';
import { ProductAction ,onLoadAllNewProduct , onLoadAllNewProductSuccess , onLoadAllPromotionProduct , onLoadAllPromotionProductSuccess} from '../actions/product.action';
import { AuthenService } from '../services/authen.service';
import { of, from } from 'rxjs';
import { ProductService } from '../services/product.service';

@Injectable()
export class ProductEffect {

   private onLoadAllProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAction.onLoadAllNewProduct),
    mergeMap((action : Action & ProductItems) => from(this.productService.onLoadAllProduct(action))
    .pipe(
        mergeMap(value =>{
            return [
                onLoadAllNewProductSuccess(value.entity)
            ]
        })
    ))
   ))

   private onLoadAllPromotionProduct$ = createEffect(() => this.actions$.pipe(
    ofType(ProductAction.onLoadAllPromotionProduct),
    mergeMap((action : Action & ProductItems) => from(this.productService.onLoadAllPromotionProduct(action))
    .pipe(
        mergeMap(value =>{
            return [
                onLoadAllPromotionProductSuccess(value.entity)
            ]
        })
    ))
   ))
    
    

    constructor(
    private actions$: Actions,
    private productService: ProductService,
    private authenService: AuthenService,
    ){}
}