import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loadding-product-list',
  templateUrl: './loadding-product-list.component.html',
  styleUrls: ['./loadding-product-list.component.scss']
})
export class LoaddingProductListComponent implements OnInit {
  @Input() itemNumber : number = 8;
  items: Array<any> = [];
  constructor() {
    
  }
  ngOnInit(): void {
    this.onFormatItems();
  }
  onFormatItems() {
    this.items = Array(this.itemNumber).fill(0).map((_, index) => index + 1);
  }
}
