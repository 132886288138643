<div class="row">
    <div class="collection-product-wrapper" id="products">
      <div class="product-wrapper-grid">
          <div class="row">
              <div class="col-grid-box" *ngFor="let item of items">
                  <div class="product-box">
                    <ng-container *ngTemplateOutlet="loadding"></ng-container>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
<ng-template #loadding>
    <div>
        <div class="img-wrapper">
          <ngx-skeleton-loader [theme]="{ 'background-color': '#e8e8e8', 'border-radius': '0', height: '240px' }"></ngx-skeleton-loader>
        </div>
        <div class="product-detail">
          <h4> 
            <ngx-skeleton-loader
              [theme]="{ 'background-color': '#e8e8e8', width: '130px', 'border-radius': '15px',  height: '10px', 'margin-bottom': '6px'}">
            </ngx-skeleton-loader>
          </h4>
          <h5>
            <ngx-skeleton-loader
              [theme]="{ 'background-color': '#e8e8e8', width: '160px', 'border-radius': '15px',  height: '12px'}">
            </ngx-skeleton-loader>
          </h5>
        </div>
    </div>
</ng-template>

   

