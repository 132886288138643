  <div class="shop-card">
      <div class="img-shop">
        <div class="tag-preferred"><i class="fa fa-thumbs-up" aria-hidden="true"></i>Yêu thích</div>
          <img
            [defaultImage]="shop?.extraInfo?.banner ? shop?.extraInfo?.banner : 'assets/images/storeDefault.jpg'" 
            [lazyLoad]="shop?.extraInfo?.banner" 
            class="img-fluid lazy-loading" 
            alt="" 
          />
      </div>
      <div class="info-shop">
          <div class="info-basic-shop">
            <!-- đánh giá của shop -->
          <bar-rating [rate]="4.5" [readOnly]="true" [className]="'rate-shop'"></bar-rating>
          <h4
              class="name-shop"
              title="Tiệm Cơm Vui Vẻ - Cơm Văn Phòng &amp; Cơm Ăn Kiêng - Shop Online"
          >
              <span
              class="icon icon-quality-merchant"
              title="Đây là 1 trong những Merchants được đánh giá cao trong ShopeeFood"
              ></span
              >{{shop.name}}
          </h4>
          <div
              class="address-shop"
              title="Số 218/20 Đống Đa, P. Thuận Phước, Quận Hải Châu, Đà Nẵng"
          >
          {{shop?.address ? shop?.address + ', ' : ''}}{{shop?.district ? shop?.district + ', ' : ''}}{{shop?.city}}
          </div>
          </div>
          <!-- <p class="content-promotion">
          <i class="fas fa-tag"></i> Giảm hết 10%
          </p>
          <div class="opentime-status">
          <span
              class="stt online"
              title="Mở cửa"
              style="
              color: rgb(35, 152, 57);
              background-color: rgb(35, 152, 57);
              "
          ></span> -->
          <!-- </div> -->
      </div>
  </div>