import { Customers } from '@pickdi/common/lib/model/Customers';
import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { NgxScannerQrcodeService, ScannerQRCodeConfig, ScannerQRCodeResult, ScannerQRCodeSelectedFiles } from 'ngx-scanner-qrcode';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { login } from 'src/app/store/actions/auth.action';
import { addToCart } from 'src/app/store/actions/cart.action';
import { RootState } from 'src/app/store/entities/state.entity';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { state } from 'src/app/shared/services/product.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-scan-qr-modal',
  templateUrl: './scan-qr-login-modal.component.html',
  styleUrls: ['./scan-qr-login-modal.component.scss']
})
export class ScanQRModalComponent {
  @ViewChild("scanQR", { static: false }) scanQR: TemplateRef<any>;
  public modalOpen: boolean = false;
  valueScan: any;
  customerCurrent: Customers;
  dataLogin:{tel: string, password: string};
  imageUrl: string | undefined;
  loadQrcode: boolean = false;
  public closeResult: string;
  @ViewChild("confirmCart") confirmCart: ConfirmModalComponent;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private modalService: NgbModal, private qrcode: NgxScannerQrcodeService, private router: Router, private destroy: DestroySubsribeService,  private toastrService: ToastrService, private store: Store<RootState>) { }
  ngOnInit(): void {
  }

  openModal() {
    this.imageUrl = null;
    this.valueScan = null;
    this.dataLogin = null;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.scanQR, { 
        size: 'sm',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'scanQR' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  handleAddImg(event) {
    this.loadQrcode = true;
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.imageUrl = e.target.result;
      };
      reader.readAsDataURL(selectedFile);
    }
    this.handleScanQrcode(event.target.files);
  }
  handleLoginByQr() {
    if (this.valueScan.length > 0 && this.valueScan[0]?.value) {
      if (this.valueScan[0]?.value?.length < 10) {
        this.loadQrcode = false;
        return this.toastrService.error('Qr code không chính xác');
      } else {
        this.dataLogin = this.handleFormatValueQR(this.valueScan[0]?.value);
        this.handleTimeScan();
      }
    } else {
      this.loadQrcode = false;
      this.toastrService.info('Hình ảnh không phải là QR code');
    }
  }
  handleSelectQr() {
   
    this.imageUrl = null;
    this.valueScan = null;
    this.dataLogin = null;
  }
  handleTimeScan() {
    let timeout = setTimeout(() => {
      this.loadQrcode = false;
      clearTimeout(timeout);
    },1200);
  }
  handleFormatValueQR(value) {
    const tel = value.slice(-10);
    const password = value.slice(0, -10);
    return {
      tel: tel,
      password: password
    }
  }
  handleLogin() {
    this.store.dispatch(login(this.dataLogin));
    this.store.select( state => state.authen.customerCurrent)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerCurrent => {
      if (!customerCurrent) return;
      this.customerCurrent = customerCurrent;
      this.modalService.dismissAll();
      if(this.dataLogin) {
      let productLocal;
        if(localStorage.getItem('cartItems') && JSON.parse(localStorage['cartItems']).length > 0) {
            productLocal = JSON.parse(localStorage['cartItems']);
            productLocal.map((product) => {
                product.totalMoney = product.priceDefault * product.quantity;
            });
        }
        if (productLocal) {    
            this.store.dispatch(addToCart({customerId: customerCurrent._id , productItem: productLocal}))
            // localStorage.removeItem('cartItems');
        } else {

        }
      this.router.navigate(['/home']);
      }
    })
  }
  handleAddProductToAccount(customerCurrent: Customers) {
    let productLocal;
    if(localStorage.getItem('cartItems') && JSON.parse(localStorage['cartItems']).length > 0) {
        productLocal = JSON.parse(localStorage['cartItems']);
        productLocal.map((product) => {
            product.totalMoney = product.priceDefault * product.quantity;
        });
    }
    if (productLocal) {    
        if(localStorage.getItem('shopId') && JSON.parse(localStorage['shopId'])) {
          if(customerCurrent.cart.length > 0 && JSON.parse(localStorage['shopId']) != customerCurrent.cart[0].shopId)  {
            this.confirmCart.openModal('Xác nhận cài lại giỏ hàng mới');
          } else {
            this.store.dispatch(addToCart({customerId: customerCurrent._id , productItem: productLocal, resetCart: false}));
            this.handleUpdateShopSlug();
            this.router.navigate(['/home']);
          }
       

        }
        
    } else {
      this.handleUpdateShopSlug();
      this.router.navigate(['/home']);
    }
    
}
handleUpdateShopSlug() {
  if(this.customerCurrent.cart.length > 0) localStorage.setItem("cartItems", JSON.stringify(this.customerCurrent.cart));
  if(this.customerCurrent?.cart[0]?.shopId) localStorage.setItem("shopId", JSON.stringify(this.customerCurrent?.cart[0]?.shopId));
  if(this.customerCurrent?.cart[0]?.shopSlug) localStorage.setItem("shopSlug", JSON.stringify(this.customerCurrent?.cart[0]?.shopSlug));
}
  handleScanQrcode(files) {
    this.qrcode.loadFilesToScan(files, {}).subscribe((res: ScannerQRCodeSelectedFiles[]) => {
      this.valueScan = res[0].data;
      this.handleLoginByQr();
    });
  }
  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }
}
