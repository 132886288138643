import ProductItemsManager  from '@pickdi/common/lib/model/manager/ProductItemsManager'
import ProductItems from '@pickdi/common/lib/model/ProductItems';
import { createReducer } from "../utils/store.util";
import { Action, on } from "@ngrx/store";
import { ProductState } from "../entities/state.entity";
import { onClearProduct, onLoadAllNewProduct , onLoadAllNewProductSuccess , onLoadAllPromotionProduct , onLoadAllPromotionProductSuccess } from '../actions/product.action'

const initialState : ProductState = {
    newProduct : [] ,
    promotionProduct : []
};

export const reducer = createReducer(
    initialState,
    on(onLoadAllNewProduct ,(state , action) =>({
        ...state
      })),
    on(onLoadAllNewProductSuccess ,(state , action) =>({
        ...state,
        newProduct : action
    })),
    on(onLoadAllPromotionProduct , (state , action) =>({
        ...state
    })),
    on(onLoadAllPromotionProductSuccess , (state , action) =>({
        ...state,
        promotionProduct : action
    })),
    on(onClearProduct , (state , action) =>({
        ...state,
        newProduct : [] ,
        promotionProduct : []
    }))
)
export function productReducer(state: ProductState | undefined, action: Action) {
    return reducer(state, action);
  }