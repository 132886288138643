import { Customers } from '@pickdi/common/lib/model/Customers';
import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from '../../services/destroySubscribe.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { logout } from 'src/app/store/actions/auth.action';
@Component({
  selector: 'app-header-one',
  templateUrl: './header-one.component.html',
  styleUrls: ['./header-one.component.scss']
})
export class HeaderOneComponent implements OnInit {
  
  @Input() class: string;
  @Input() themeLogo: string = 'assets/images/snapedit_1695888244747(1).png'; // Default Logo
  @Input() topbar: boolean = true; // Default True
  @Input() sticky: boolean = false; // Default false
  hiddenMenu: boolean = true; // show | hide -> menu
  infoCustomer: Customers;
  public stick: boolean = false;

  constructor(private store: Store<RootState>, private destroy: DestroySubsribeService, private router: Router) { }

  ngOnInit(): void {
    this.handleInfoCustomer();
  }
  handleLogout() {
    this.store.dispatch(logout());
  }
  handleInfoCustomer() {
    this.store.select(state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe((customer: Customers) => {
      if (!customer || Object.keys(customer).length === 0) return this.infoCustomer = null;
      this.infoCustomer = _.cloneDeep(customer);
    })
  }
  onNavigate(path: string) {
    this.router.navigate([`pages/${path}`]);
    let timeout = setTimeout(() => {
      this.hiddenMenu= true;
      clearTimeout(timeout)
    }, 200);
  }
  // @HostListener Decorator
  @HostListener("window:scroll", [])
  onWindowScroll() {
    let number = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  	if (number >= 150 && window.innerWidth > 400) { 
  	  this.stick = true;
  	} else {
  	  this.stick = false;
  	}
  }

}
