import { createAction, props } from '@ngrx/store';
import Staffs from '@pickdi/common/lib/model/Staffs';
import Customers from '@pickdi/common/lib/model/Customers';
import { AuthenState, HandleError } from '../entities/state.entity';


export const AuthActions = {
  login: '[App] Login',
  logout: '[App] Logout',
  loadAuthen: '[App] Login load Authen',
  loadAuthenInit: '[App] Login load Authen Init',
  loginSucess: '[App] Login Sucess',
  loadUnAuthen: '[App] Login load UnAuthen',
  changeViewScreen: '[App] on change view screen',
  register: '[App] Register',
  registerSucess: '[App] Register Sucess',
  registerMessage: '[App] Register Message',
  getOtpVerification: '[App] get OTP Verification', 
  confirmOtpVerification: '[App] confirm OTP Verification',
  confirmOtpVerificationSucess: '[App] confirm OTP Verification sucess',
  loginMessage: '[App] Login Message',
  clearStateAuth: '[App] clear state authentication'
  // getOtpVerificationSusscer : '[App] get OTP Verification susscer',
};

export const register = createAction(AuthActions.register,
  props<Customers>()
);
export const registerMessage = createAction(AuthActions.registerMessage,
  props<{message: string, code: string, data: Customers}>()
);
export const loginMessage = createAction(AuthActions.loginMessage,
  props<{message: string, code: string}>()
);
export const registerSucess = createAction(AuthActions.registerSucess,
  props<Customers>()
);
export const getOtpVerification = createAction(AuthActions.getOtpVerification,
  props<{tel : string}>()
);
export const confirmOtpVerification = createAction(AuthActions.confirmOtpVerification,
  props<{tel : string, OTP: string}>()
);

// export const getOtpVerificationSusscer = createAction(AuthActions.getOtpVerificationSusscer,
//   props<{tel : string}>()
// );
export const clearStateAuth = createAction(AuthActions.clearStateAuth);
export const login = createAction(AuthActions.login,
  props<{tel: string, password: string}>()
);
export const loginSucess = createAction(AuthActions.loginSucess,
  props<Customers>()
);
export const logout = createAction(AuthActions.logout);
export const loadAuthenInit = createAction(AuthActions.loadAuthenInit);
export const loadAuthen = createAction(AuthActions.loadAuthen,
  props<AuthenState>()
);
export const loadUnAuthen = createAction(AuthActions.loadUnAuthen,
  props<HandleError>()
);
export const changeViewScreen = createAction(AuthActions.changeViewScreen,
  props<{ value: boolean }>()
);


