import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Customers } from '@pickdi/common/lib/model/Customers';
import { Injectable } from "@angular/core";
import CustomersManager  from '@pickdi/common/lib/model/manager/CustomersManager';
import { LoadingBarService } from '@ngx-loading-bar/core';
import * as _ from 'lodash';
@Injectable()
export class CartsService {
  constructor(
    private loadingBar: LoadingBarService,
    ) { }
  async addToCart(action): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/addToCart';
    return customersManager.insert(action);
  }
  async onUpdateCart(action): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/updateCart';
    let customerId = action.customerId;
    // let productItem = _.cloneDeep(action.productItem);
    let data = 
      {
      customerId: customerId,
      _id: action.productItem._id,
      productName : action.productItem.productName,
      totalMoney: action.productItem.totalMoney,
      quantity: action.productItem.quantity,
    }
  
    return customersManager.update({customerId: customerId}, data );
  }
  async onDeleteCartItem(action): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/deleteCart';
    return customersManager.delete(action);
  }
}