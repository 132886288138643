import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError} from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { CartActions, addToCartSuccess, onDeleteCartItemSuccess, onUpdateCartSuccess } from '../actions/cart.action';
import { CartsService } from '../services/cart.service';
import { from, of } from 'rxjs';
import { AuthenService } from '../services/authen.service';
import { loadCustomerLogin } from '../actions/customer.action';

@Injectable()
export class CartEffect {
    
                
    private addToCart$ = createEffect(() => this.action$.pipe(
        ofType(CartActions.addToCart),
        mergeMap((action: Action & {carts: ProductItems[]}) => from(this.cartsService.addToCart(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();              
                    return [
                        addToCartSuccess({carts: value.entity.cart})
                        // hiddenProgress()];
                    ]
                }),
                catchError(err => {
                    this.authenService.handleAuthError(err, of());
                    this.loadingBar.stop();
                    return [
                        // clearStateCustomer(),
                    ]
                }  
                
                ))
            )
        )
    );
    private onDeleteCartItem$ = createEffect(() => this.action$.pipe(
        ofType(CartActions.onDeleteCartItem),
        mergeMap((action: Action & ProductItems) => from(this.cartsService.onDeleteCartItem(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();      
                    return [
                        onDeleteCartItemSuccess({carts: value?.entity?.cart})
                        // hiddenProgress()];
                    ]
                }),
                catchError(err => {
                    this.authenService.handleAuthError(err, of());
                    this.loadingBar.stop();
                    return [
                        // clearStateCustomer(),
                    ]
                }  
                
                ))
            )
        )
    );
    private onUpdateCart$ = createEffect(() => this.action$.pipe(
        ofType(CartActions.onUpdateCart),
        mergeMap((action: Action & ProductItems) => from(this.cartsService.onUpdateCart(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();      
                    return [
                        onUpdateCartSuccess({carts: value?.entity?.cart})
                        // hiddenProgress()];
                    ]
                }),
                catchError(err => {
                    this.authenService.handleAuthError(err, of());
                    this.loadingBar.stop();
                    return [
                        // clearStateCustomer(),
                    ]
                }  
                
                ))
            )
        )
    );
    constructor(private action$: Actions, private cartsService: CartsService, private authenService: AuthenService,  private loadingBar: LoadingBarService) { }
}