import { Component, OnInit } from '@angular/core';
import { NavService, Menu } from '../../services/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit {

  public menuItems: Menu[];

  constructor(private router: Router, public navServices: NavService) {
    // this.navServices.leftMenuItems.subscribe((menuItems) => {this.menuItems = menuItems});
    this.menuItems = [
      {
        active : false ,
        megaMenu: false ,
        title : 'Nhà Hàng',
        type : 'sub'
      },
      {
        active : false,
        megaMenu : false ,
        children : [ 
          {
            active : false ,
            title : 'Ăn Sáng',
            type : 'link'
          },
          {
            active : false ,
            title : 'Ăn Trưa',
            type : 'link'
          },
          {
            active : false ,
            title : 'Ăn Tối',
            type : 'link'
          },
          {
            active : false ,
            title : 'Cơm',
            type : 'link'
          },
          {
            active : false ,
            title : 'Mỳ',
            type : 'link'
          }
        ],
        title : 'Quán Ăn',
        type : 'sub',
      },
      {
        active : false ,
        megaMenu : false,
        title : 'Quán Nhậu',
        type : 'sub',
      },
      {
        active : false ,
        megaMenu : false,
        title : 'Quán Nướng',
        type : 'sub',
      },
      {
        active : false ,
        megaMenu : false,
        title : 'Hải Sản',
        type : 'sub',
      },
      {
        active : false ,
        megaMenu : false ,
        title : 'Món Chay',
        type : 'sub',
      },
      {
        active : false ,
        megaMenu : false ,
        title : 'Đặc Sản',
        type : 'sub',
      },
      {
        active :false,
        megaMenu : false,
        title : 'Cafe',
        type : 'sub',
      },
      {
        active : false,
        megaMenu : false,
        title : 'Bar/Pub',
        type : 'sub',
      }
    ]
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
  }

  ngOnInit(): void {
  }

  leftMenuToggle(): void {
    this.navServices.leftMenuToggle = !this.navServices.leftMenuToggle;
  }

  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }

  onHover(menuItem) {
    if(window.innerWidth > 1200 && menuItem){
       document.getElementById('unset').classList.add('sidebar-unset')
    } else {
      document.getElementById('unset').classList.remove('sidebar-unset')
    }
  }

}
