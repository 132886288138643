<ng-template class="theme-modal" #selectTableModal let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-header modal-bg">
            <h4 >Chọn bàn bạn đang ngồi tại quán</h4>
            <button type="button" id="close-cart-modal" class="btn close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
           <div class="tabs">
            <ng-container *ngIf="tables else noTables">
              <mat-tab-group  >
                <div *ngFor="let table of tables">
                  <mat-tab *ngIf="table?.floor" [label]="'Tầng '+ table?.floor">
                    <div class="content-tabs" >
                      <div class="table-floor" >
                          <div class="table-floor__item selectTable ng-star-inserted" *ngFor="let data of table?.data">
                            <div class="content-grid" (click)="handleSelectTable(data)">
                              <div class="chair1" [ngClass]="{'chair-select' : tableSelect?._id == data?._id}"><span></span><span></span></div>
                              <span class="table-name" [ngClass]="{'chair-select' : tableSelect?._id == data?._id}"
                                ><!---->
                                {{data?.tableName}}</span
                              >
                              <div class="chair2" [ngClass]="{'chair-select' : tableSelect?._id == data?._id}"><span></span><span></span></div>
                            </div>
                          </div>

                      </div>
                    </div>
                </mat-tab>
                </div>
               
                
              </mat-tab-group>
            </ng-container>
              <ng-template #noTables>


              </ng-template>
              
           </div>
            
              
        </div>
    </div>
</ng-template>