import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { RootState } from './entities/state.entity';
import { ActionReducerMap, Store, StoreModule } from '@ngrx/store';
import { authenReducer } from './reducers/authen.reducer';
import { AuthenService } from './services/authen.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthenEffect } from './effects/authen.effect';
import { loadAuthenInit } from './actions/auth.action';
import { customerReducer } from './reducers/customer.reducer';
import { CustomerEffect } from './effects/customer.effect';
import { CustomersService } from './services/customer.service';
import { MenuEffect } from './effects/menu.effect';
import { menusReducer } from './reducers/menu.reducer';
import { MenusService } from './services/menu.service';
import { shopsReducer } from './reducers/shop.reducer';
import { ShopsService } from './services/shop.service';
import { ShopEffect } from './effects/shop.effect';
import { productReducer } from './reducers/product.reducer';
import { ProductEffect } from './effects/product.effect';
import { ProductService } from './services/product.service'
import { OrderEffect } from './effects/order.effect';
import { ordersReducer } from './reducers/order.reducer';
import { OrdersService } from './services/order.service';
import { loaddingReducer } from './reducers/loadding.reducer';
import { PWA_app_Service } from './services/PWA_app.service';
import { cartReducer } from './reducers/cart.reducer';
import { CartEffect } from './effects/cart.effect';
import { CartsService } from './services/cart.service';

const reducers: ActionReducerMap<RootState> = {  
  authen: authenReducer,
  customer: customerReducer,
  menu: menusReducer,
  productItem : productReducer,
  order : ordersReducer,
  shop: shopsReducer,
  loadding: loaddingReducer,
  cart: cartReducer

};

@NgModule({
  imports: [
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([
      AuthenEffect,
      CustomerEffect,
      MenuEffect,
      ShopEffect,
      ProductEffect,
      OrderEffect,
      CartEffect

  ]),
  HttpClientModule
  ],
  exports: [
    StoreModule,
    EffectsModule
],
providers: [
  AuthenService,
  CustomersService,
  MenusService,
  ShopsService,
  ProductService,
  OrdersService,
  PWA_app_Service,
  CartsService
]
})
export class AppStoreModule {
  constructor(private store: Store<RootState>) {
    this.store.dispatch((loadAuthenInit()));
  }
}
