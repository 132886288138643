<div *ngIf="!loader">
  <div class="img-wrapper">
    <div class="lable-block">
      <!-- <span class="lable3" >new</span>
      <span class="lable4" >on sale</span> -->
    </div>
    <div class="front">
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]"> -->
        <img 
          [defaultImage]="product?.extraInfo?.banner ? product?.extraInfo?.banner : 'assets/images/storeDefault.jpg'" 
          [lazyLoad]="product.image" 
          class="img-fluid lazy-loading" 
          alt="" />
      <!-- </a> -->
    </div>
    <!-- <div class="back" *ngIf="onHowerChangeImage">
      <a [routerLink]="['/shop/product/left/sidebar/', product.title.replace(' ', '-')]">
        <img [src]="ImageSrc ? ImageSrc : product.images[1].src" class="img-fluid lazy-loading" alt="{{ product.images[1].alt }}">
      </a>
    </div> -->
    <!-- <ul class="product-thumb-list" *ngIf="thumbnail">
      <li class="grid_thumb_img" [class.active]="ImageSrc == image.src" *ngFor="let image of product.images">
        <a href="javascript:void(0)" (mouseover)="ChangeVariantsImage(image.src)">
          <img [lazyLoad]="image.src">
        </a>
      </li>
    </ul> -->
    <!-- <div class="cart-info cart-wrap">
    
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)">
        <i class="ti-shopping-cart"></i>
      </a>
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Quick View" (click)="QuickView.openModal()">
        <i class="ti-search" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Compare" (click)="addToCompare(product)">
        <i class="ti-reload" aria-hidden="true"></i>
      </a>
    </div> -->
  </div>
  <div class="product-detail">
    <div>
      <bar-rating [rate]="product?.extraInfo?.rateStar" *ngIf="product?.extraInfo?.rateStar"  [readOnly]="true"></bar-rating>
      <!-- <a [routerLink]="['/shop/product/left/sidebar/', product?.title.replace(' ', '-')]"> -->
        <h4 (click)="onNavigate(product.slug)" style="cursor: pointer;">{{ product?.name | titlecase }}</h4>
      <!-- </a> -->
      <!-- mo ta san pham -->
      <!-- <p>{{ product.description }}</p> -->
      <!-- <h4 *ngFor="let price of product?.prices">
        {{price?.name}} : {{ price?.price | currency:'VND':'symbol':null:'vi-VN'}}
      </h4>
      <del *ngIf="product?.priceDefault"><span class="money"> {{ product?.priceDefault | currency:'VND':'symbol':null:'vi-VN' }}</span></del> -->
    </div>
  </div>
</div>
<app-skeleton-product-box *ngIf="loader"></app-skeleton-product-box>
<app-quick-view #quickView [product]="product" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>

