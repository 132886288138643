import { DatePipe } from "@angular/common";
import { Injectable } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";



export const
regexFormEmail = Validators.pattern(/^(\d{10}|\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/);

export const
regexFormTel = Validators.pattern(/^\d{4}\s\d{3}\s\d{3}$/);


/**
 * function check valid email & tel
 */
export const
regexFormPattern = (form, fieldName):boolean => {
  let formControl = form?.controls[fieldName];
  return  formControl?.hasError('pattern') && (formControl?.dirty || formControl?.touched);
}

export const 
validateForm = (form, fieldName):boolean => {
  let formControl = form?.controls[fieldName]
  return formControl?.invalid && (formControl?.dirty || formControl?.touched)
}

export const
validateAllFormFields = (form: FormGroup) => {
  Object.keys(form.controls).forEach(field => {
    const control = form.get(field);
    if (control instanceof FormControl) {
      control.markAsTouched({ onlySelf: true });
    } else if (control instanceof FormGroup) {
      validateAllFormFields(control);    }
  });
}

/**
 *
 * function scroll to field unvalid
 */
export const 
scrollToFirstInvalidControl = (form: FormGroup) => {
  let firstControlsInvalid = Object.keys(form.controls).find(field=> form.get(field).invalid);
  document.getElementById(firstControlsInvalid)?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  document.getElementById(firstControlsInvalid)?.focus();
}

@Injectable()
export class FormatDateComponent {
  constructor(
    private datePipe: DatePipe
  ) { }

  formatDate = (date: string, type: string): string | null => {
    if (!date) return null;
    var dateArray = date.split('-' || '/');
    var convertedDate = new Date(dateArray[1] + '-' + dateArray[0] + '-' + dateArray[2]);
    if (/Mobi|iPad|iPhone|iPod/i.test(navigator.userAgent) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) {
      convertedDate = new Date(dateArray[2] + '/' + dateArray[1] + '/' + dateArray[0]);
    }
    return this.datePipe.transform(convertedDate, type);
  }
}