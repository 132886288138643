import { Feedbacks } from '@pickdi/common/lib/model/Feedbacks';
import { createAction, props } from '@ngrx/store';
import { Customers } from '@pickdi/common/lib/model/Customers';
export const CustomerActions = {
    loadCustomerLogin: '[Customer] load Customer Login',
    loadCustomerLoginSuccess: '[Customer] load Customer Login Success',
    clearStateCustomer:'[Customer] clear Customer',
    updataInfoCustomer : '[Customer] update Info Customer',
    updateInfoCustomerSuccess : '[Customer] update Info Customer Success',
    changeTelCustomer: '[Customer] change tel Customer',
    changePasswordNew: '[Customer] change password Customer',
    changePasswordNewSuccess : '[Customer] change password Success',
    confirmChangeTelCustomer : '[Customer] confirm change tel Customer',
    confirmChangeTelCustomerSuccess : '[Customer] confirm change tel Customer Success',
    clearConfirmTelChange: '[Customer] clear tel change',
    isConfirmPassword: '[Customer] is confirm password',

    
    isForgotPassword: '[Customer] is forgot password',
    isForgotPasswordSuccess: '[Customer] is forgot password Success',

    isConfirmForgotPassword: '[Customer] is confirm forgot password',
    isConfirmForgotPasswordSuccess: '[Customer] is confirm forgot password Success',

    onFeedbackProduct: '[Customer] on feedback for customer ',

    isConfirmOTPForgotPassword: '[Customer] is confirm OTP forgot password',
    isConfirmOTPForgotPasswordSuccess: '[Customer] is confirm OTP forgot password Success',

    isChangePasswordForgotPassword:  '[Customer] is change password forgot password',
    isChangePasswordForgotPasswordSuccess:  '[Customer] is change password forgot password Success',
  };
  
  export const loadCustomerLogin = createAction(CustomerActions.loadCustomerLogin,
    props<{customerId: string}>()
  );
  export const loadCustomerLoginSuccess = createAction(CustomerActions.loadCustomerLoginSuccess,
    props<Customers>()
  ); 
  export const clearStateCustomer = createAction(CustomerActions.clearStateCustomer);
  export const isConfirmPassword = createAction(CustomerActions.isConfirmPassword , props<{isConfirm: boolean}>());
  export const updataInfoCustomer = createAction(CustomerActions.updataInfoCustomer , props<Customers>());

  export const changeTelCustomer = createAction(CustomerActions.changeTelCustomer,
    props<{_id: string , tel: string, password: string}>() //_id customer
  );
  export const changePasswordNew = createAction(CustomerActions.changePasswordNew,
    props<Customers>() //_id customer
  );
  export const changePasswordNewSuccess = createAction(CustomerActions.changePasswordNewSuccess,
    props<Customers>()
  ); 

  export const confirmChangeTelCustomer = createAction(CustomerActions.confirmChangeTelCustomer,
    props<Customers>() //_id customer
  );
  export const confirmChangeTelCustomerSuccess = createAction(CustomerActions.confirmChangeTelCustomerSuccess,
    props<Customers>()
  ); 

    
  export const isForgotPassword = createAction(CustomerActions.isForgotPassword,
    props<{tel: string}>() 
  );
  export const isForgotPasswordSuccess = createAction(CustomerActions.isForgotPasswordSuccess,
    props<{isForgotPassword: boolean}>() 
  );
  export const isConfirmOTPForgotPassword = createAction(CustomerActions.isConfirmOTPForgotPassword,
    props<{tel: string, OTP: string}>()
  );
  export const isConfirmOTPForgotPasswordSuccess = createAction(CustomerActions.isConfirmOTPForgotPasswordSuccess,
    props<{isConfirmOTPForgotPassword: boolean}>() 
  ); 

  export const onFeedbackProduct = createAction(CustomerActions.onFeedbackProduct ,
    props<Feedbacks>());
  export const isChangePasswordForgotPassword = createAction(CustomerActions.isChangePasswordForgotPassword,
    props<{tel: string, password: string, confirmPassword: string}>()
  );
  export const isChangePasswordForgotPasswordSuccess = createAction(CustomerActions.isChangePasswordForgotPasswordSuccess,
    props<{isChangePasswordForgotPassword: boolean}>() 
  ); 
  


  export const clearConfirmTelChange = createAction(CustomerActions.clearConfirmTelChange); 