import Constants  from '@pickdi/common/lib/base/Constants';
import WebOrders  from '@pickdi/common/lib/model/WebOrders';
import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product.service';
import { STATUS_ORDER } from 'src/app/shared/enum/share.enum';
import { EvaluateProductComponent } from '../evaluate-product/evaluate-product.component';

@Component({
  selector: 'app-view-order-detail',
  templateUrl: './view-order-detail.component.html',
  styleUrls: ['./view-order-detail.component.scss']
})
export class OrderViewDetailComponent implements OnInit, OnDestroy  {
  @ViewChild("orderViewDetail", { static: false }) orderViewDetail: TemplateRef<any>;
  @ViewChild("evaluateProduct") evaluateProduct: EvaluateProductComponent;
  public closeResult: string;
  WEB_ORDER = Constants.WEB_ORDER;
  STATUS_ORDER = STATUS_ORDER;
  orderDetail: WebOrders;
  public modalOpen: boolean = false;
  productSelectAddToCart: ProductItems;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
     private modalService: NgbModal,
    public productService: ProductService) { }

  ngOnInit(): void {
  }
  checkStatusOrder(status: string): string {
    if (status == this.WEB_ORDER.WAIT) {
      return this.orderDetail.orderDateTime;
    } else if(status == this.WEB_ORDER.RECEIVED || status == this.WEB_ORDER.RECEIVED_KICHEN ||  status == this.WEB_ORDER.IN_PROCESS_KICHEN || status === this.WEB_ORDER.COMPLETE_KICHEN || status === this.WEB_ORDER.COMPLETE) {
      return this.orderDetail.timeChangeStatus;
    } else { //cancel_order
         return this.orderDetail.orderDateTime; 
    }
  }
  checkStepStatus(step: string) {
    const status = this.orderDetail.status;
    switch (step) {
      case this.WEB_ORDER.WAIT:
        return  status === this.WEB_ORDER.IN_PROCESS_KICHEN || status === this.WEB_ORDER.WAIT || status === this.WEB_ORDER.RECEIVED || status === this.WEB_ORDER.RECEIVED_KICHEN || status === this.WEB_ORDER.COMPLETE_KICHEN || status === this.WEB_ORDER.COMPLETE;
      case this.WEB_ORDER.RECEIVED:
        return status === this.WEB_ORDER.IN_PROCESS_KICHEN || status === this.WEB_ORDER.RECEIVED || status === this.WEB_ORDER.RECEIVED_KICHEN || status === this.WEB_ORDER.COMPLETE_KICHEN || status === this.WEB_ORDER.COMPLETE;
      case this.WEB_ORDER.IN_PROCESS_KICHEN:
        return status === this.WEB_ORDER.IN_PROCESS_KICHEN || status === this.WEB_ORDER.COMPLETE_KICHEN || status === this.WEB_ORDER.COMPLETE;
      case this.WEB_ORDER.COMPLETE_KICHEN:
        return status === this.WEB_ORDER.COMPLETE_KICHEN || status === this.WEB_ORDER.COMPLETE;
      case this.WEB_ORDER.COMPLETE:
        return status === this.WEB_ORDER.COMPLETE;
      default:
        return false;
    }
  }
  getElapsedTime(): string {
    let timeOrder = this.checkStatusOrder(this.orderDetail.status);
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    let dayTimeCurrent = (new Date(Date.now() - tzoffset)).toISOString().slice(0, -1);
    let serviceTime = new Date(dayTimeCurrent.split('.')[0]).getTime() - new Date(timeOrder.split('.')[0]).getTime();
    var days = Math.floor(serviceTime / (60 * 60 * 24 * 1000));
    var hours = Math.floor(serviceTime / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(serviceTime / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    return days > 0 ? `${days} Ngày` : `${hours > 9 ? hours : `0${hours}`}:${minutes > 9 ? minutes : `0${minutes}`}`;
  }
  handleEvaluate(product: ProductItems) {
    this.modalService.dismissAll();
    this.evaluateProduct.openModal(product, this.orderDetail);
    // this.modalService.open(this.orderViewDetail); // mở lại modal detail nếu cần
    
  }
  openModal(orderDetail: WebOrders) {
    this.orderDetail = orderDetail;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.orderViewDetail, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'OrderViewDetail' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
      // this.modalService.hasOpenModals
    }
  }

}

