<!--modal popup start-->
<ng-template class="theme-modal" #confirmPasswordModal let-modal>
	<div class="modal-content quick-view-modal">
        <div class="modal-body">
            <div class="p-0">
                <div class="row">
                    <div class="col-12">
                        <div class="modal-bg">
                            <div class="age-content" [formGroup]="passwordConfirmForm">
                                <button type="button" id="close-cart-modal" class="btn close" (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                                <div class="mt-3">
                                    <e-commerce-input
                                        [label]="'Xác nhận mật khẩu'"
                                        [formName]="'passwordConfirm'"
                                        [required]="true"
                                        [typeInput]="'password'"
                                        [preValue]="passwordConfirmForm.value['passwordConfirm']"
                                        [formControl]="passwordConfirmForm?.controls.passwordConfirm"
                                        [inputRequired]="validateForm(passwordConfirmForm, 'passwordConfirm')"
                                        [inputValid]="passwordConfirmForm.controls.passwordConfirm.errors?.required"
                                    >
                                    <div class="regexForm" >
                                        <ng-container *ngIf="passwordConfirmForm.controls.passwordConfirm.errors?.minlength">
                                            <p class="text-danger text-left" >Mật khẩu tối thiểu 6 kí tự</p>
                                        </ng-container>
                                        <ng-container *ngIf="passwordConfirmForm.controls.passwordConfirm.errors?.maxlength">
                                            <p class="text-danger text-left" >Mật khẩu tối đa 32 kí tự</p>
                                        </ng-container>
                                    </div>
                                    </e-commerce-input>
                                </div>
                                <div class="btn-confirm">
                                    <button type="submit" (click)="handleConfirm()" class="btn btn-solid">
                                        Xác nhận
                                    </button>
                                    <button type="submit " (click)="handleNoConfirm()" class="btn btn-solid">
                                        Hủy
                                    </button>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
<!--modal popup end-->