import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input, AfterViewInit,
    Injectable, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { scrollToFirstInvalidControl, validateAllFormFields, validateForm } from 'src/app/shared/functions/function-helper';
import { takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';

@Component({
  selector: 'app-confirm-pasword-modal',
  templateUrl: './confirm-password-modal.component.html',
  styleUrls: ['./confirm-password-modal.component.scss']
})
export class ConfirmPasswordModalComponent implements OnInit, OnDestroy {

  @ViewChild("confirmPasswordModal") ConfirmModal: TemplateRef<any>;
  @Output() resultConfirm : EventEmitter<any> = new EventEmitter<any>();
  passwordValue: string;
  passwordConfirmForm: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;
  validateForm = validateForm;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private destroy: DestroySubsribeService,
    private store: Store<RootState>,
  	private fb: UntypedFormBuilder) { 
  }

  ngOnInit(): void {
   
    this.passwordConfirmForm = this.createFormPasswordConfirm();
    this.store.select(state => state.customer.isConfirmPassword)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe((isConfirm: boolean) => {
      if (!isConfirm) return;
      this.modalService.dismissAll();
    })
  }
  createFormPasswordConfirm(): FormGroup {
    return this.formBuilder.group({
      passwordConfirm: ['', [Validators.minLength(6), Validators.maxLength(32), Validators.required]],
    })
  }
  handleConfirm() {
    if (this.passwordConfirmForm.valid) {
      this.resultConfirm.emit([true, this.passwordConfirmForm.value]);
    } else {
      validateAllFormFields(this.passwordConfirmForm);
      scrollToFirstInvalidControl(this.passwordConfirmForm);
    }
  }
  handleNoConfirm() {
    this.resultConfirm.emit([false]);
    this.modalService.dismissAll();
  }
  openModal() {
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.ConfirmModal, { 
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
        windowClass: 'bd-example-modal-md theme-modal agem'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.resultConfirm.emit([false]);
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
