<ng-template  #createPasswordNew let-modal>
    <div class="modal-content">
        <div class="modal-header justify-content-start ">
            <!-- <i class="fa fa-arrow-left btn-arrow-left" aria-hidden="true"></i> -->
            <!-- <i class="fa fa-long-arrow-left btn-arrow-left" aria-hidden="true"></i> -->
            <i class="fa fa-chevron-left btn-arrow-left" (click)="handlePreStep()" aria-hidden="true"></i>
            <h4 class="title-modal">Đặt lại mật khẩu</h4>
        </div>
        <div class="modal-body"style="background-image: none;">
            <div class="p-0">
                <div class="row">
                    <div class="col-12"  [ngClass]="{'loadding': isLoadding}" >
                        <!-- <div class="otp" *ngIf="confirm">
                                <app-otp-layout [tel]="tel" (handleRefreshOTP)="handleRefreshOTP()" (handleSubmitOTP)="handleSubmitOTP($event)"></app-otp-layout>
                        </div> -->
                        <div class="loading-spinner" *ngIf="isLoadding" style="margin: 0 auto;"></div>
                        <div class="tel-comfirm"  [formGroup]="telForm" *ngIf="!confirmTel && !isLoadding && !confirmOtp">
                            <e-commerce-input
                            [label]="'Số điện thoại của tài khoản'"
                            [formName]="'telConfirm'"
                            [required]="true"
                            [pattern]="telPattern"
                            [inputType]="'phoneNumber'"
                            [preValue]="telForm.value['telConfirm']"
                            [formControl]="telForm?.controls.telConfirm"
                            [inputRequired]="validateForm(telForm, 'telConfirm')"
                            [inputValid]="telForm.controls.telConfirm.errors?.required"
                            >
                            <div class="regexForm" >
                                <ng-container *ngIf="regexFormPattern(telForm, 'telConfirm')">
                                    <p class="text-danger text-left" >Số điện thoại không hợp lệ</p>
                                </ng-container>
                            </div>
                            </e-commerce-input>
                        </div>
                        <div class="otp" *ngIf="confirmTel && !confirmOtp && !isLoadding" >
                            <app-otp-layout [tel]="tel" [secondsRemaining]="59" (handleRefreshOTP)="handleRefreshOTP()" (handleSubmitOTP)="handleSubmitOTP($event)"></app-otp-layout>
                        </div>
                        <div class="password-and-tel" *ngIf="!isLoadding && confirmTel && confirmOtp"  [formGroup]="passwordNewForm">
                            <div>
                                <e-commerce-input
                                [label]="'Mật khẩu mới'"
                                [formName]="'passwordNew'"
                                [required]="true"
                                [typeInput]="'password'"
                                [preValue]="passwordNewForm.value['passwordNew']"
                                [formControl]="passwordNewForm?.controls.passwordNew"
                                [inputRequired]="validateForm(passwordNewForm, 'passwordNew')"
                                [inputValid]="passwordNewForm.controls.passwordNew.errors?.required"
                                >
                                <div class="regexForm" >
                                    <ng-container *ngIf="passwordNewForm.controls.passwordNew.errors?.minlength">
                                        <p class="text-danger text-left" >Mật khẩu tối thiểu 6 kí tự</p>
                                    </ng-container>
                                    <ng-container *ngIf="passwordNewForm.controls.passwordNew.errors?.maxlength">
                                        <p class="text-danger text-left" >Mật khẩu tối đa 32 kí tự</p>
                                    </ng-container>
                                </div>
                                </e-commerce-input>
                            </div>
                            <div class="mt-1">
                                <e-commerce-input
                                [label]="'Xác nhận mật khẩu mới'"
                                [formName]="'passwordConfirm'"
                                [typeInput]="'password'"
                                [preValue]="passwordNewForm.value['passwordConfirm']"
                                [formControl]="passwordNewForm?.controls.passwordConfirm"
                                    >
                                    <div class="regexForm">
                                        <div class="regexForm">
                                            <ng-container *ngIf="passwordNewForm.hasError('notSame')">
                                                <p class="text-danger">Mật khẩu không trùng nhau</p>
                                            </ng-container>
                                        </div>
                                    </div>
                            </e-commerce-input>
                            </div>
                        </div>
                        <div class="btn-confirm d-flex justify-content-end mt-2" *ngIf="!isLoadding && ((!confirmTel && !confirmOtp) || (confirmTel && confirmOtp))">
                            <button type="submit" (click)="handleSubmitForm()" class="btn btn-solid" style="margin-right: 10px;">
                                Xác nhận
                            </button>
                            <button type="submit" (click)="handleNoConfirm()" class="btn btn-solid ">
                                Hủy
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>



