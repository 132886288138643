<div class="icon-nav">
  <ul>
    <li class="onhover-div mobile-search search-widgets" id="search-widgets">
      <div (click)="searchToggle()">
        <img src="assets/images/icon/search.png" class="img-fluid" alt="">
        <i class="ti-search"></i>
      </div>
      <div id="search-overlay" class="search-overlay" [ngStyle]="{'display': search ? 'block' : 'none' }">
        <div class="container"> 
            <span class="closebtn" title="Close Overlay" (click)="searchToggle()">×</span>
            <div class="overlay-content">
                    <div class="row">
                        <div class="col-xl-12">
                            <form>
                                <div class="form-group search-block">
                                    <input type="text" class="form-control text-center" (input)="handleSearch($event)" id="exampleInputPassword1" placeholder="Tìm kiếm món ăn, quán ăn...">
                                    <div class="result-search" *ngIf="!isLoadding && nameSearch != ''">
                                        <a class="shop-item"  *ngFor="let shop of listSearchShopOrProduct" [routerLink]="['/shop/'+ shop.slug]">
                                          <div class="info-shop">
                                            <div class="img-shop">
                                              <img  style="display: block;" [defaultImage]="shop?.extraInfo?.banner ? shop?.extraInfo?.banner : 'assets/images/storeDefault.jpg'" 
                                              [lazyLoad]="shop?.extraInfo?.banner" 
                                              class="img-fluid lazy-loading" 
                                              alt="" />
                                            </div>
                                            <div class="content-info-shop">
                                                <h6 class="name-shop">{{shop.name}}</h6>
                                                <p class="address-shop">{{shop?.address ? shop?.address + ', ' : ''}}{{shop?.district ? shop?.district + ', ' : ''}}{{shop?.city}}</p>
                                            </div>
                                          </div>
                                          <div class="online">
                                            <span class="is-online on"  *ngIf="setOpeningAndClosingTime(shop?.extraInfo?.timeOpen, shop?.extraInfo?.timeClose) else noOnline" >Mở cửa <p class="fa fa-circle" aria-hidden="true"></p></span>
                                            <ng-template #noOnline>
                                              <span class="is-online off">Đóng cửa <p class="fa fa-circle-o" aria-hidden="true"></p></span>
                                            </ng-template>
                                          </div>
                                        </a>
                                        <div class="result-last" (click)="redirectToWithQueryParam()">
                                          <p class="fa fa-search" ></p>
                                          {{listSearchShopOrProduct.length > 0 ? 'Tìm kiếm nâng cao' : 'Không tìm thấy kết quả nào'}}
                                        </div>
                                        
                                    </div>
                                    <div class="loading-spinner mt-2" *ngIf="isLoadding" style="margin: 0 auto;"></div>
                                     <!-- <div class="no-result">
                                      Không tìm thấy kết quả nào
                                     </div> -->
                                  </div>
                            </form>
                        </div>
                </div>
            </div>
        

        </div>
      </div>
    </li>
    <li class="onhover-div mobile-setting">
      <div><img src="assets/images/icon/setting.png" class="img-fluid" alt="settong">
        <i class="ti-settings"></i></div>
      <div class="show-div setting">
        <h6>language</h6>
        <ul>
          <li *ngFor="let language of languages">
            <a href="javascript:void(0)" (click)="changeLanguage(language.code)">{{ language.name }}</a>
          </li>
        </ul>
        <h6>currency</h6>
        <ul class="list-inline">
          <li *ngFor="let currency of currencies">
            <a href="javascript:void(0)" (click)="changeCurrency(currency)">{{ currency.name }}</a>
          </li>
        </ul>
      </div>
    </li>
    <li class="onhover-div mobile-cart" (mouseleave)="hiddenMenu = true" (mouseenter)="hiddenMenu = false" (click)="hiddenMenu = false">
      <div>
        <a [routerLink]="['/pages/cart']">
          <img src="assets/images/icon/cart.png" class="img-fluid" alt="">
          <i class="ti-shopping-cart"></i>
        </a>
      </div>
      <span class="cart_qty_cls">{{ products.length }}</span>
      <ul class="show-div shopping-cart"  *ngIf='!products.length'>
        <h5>Giỏ hàng trống!</h5>
      </ul>
      <ul class="show-div shopping-cart" [ngClass]="{'hidden-menu': hiddenMenu}" *ngIf='products.length'>
        <li *ngFor="let product of products">
          <div class="media">
            <a >
              <img class="me-3" [src]="product?.image ? product.image : 'assets/images/product/placeholder.jpg'" onerror="this.src = 'assets/images/product/placeholder.jpg'" [alt]="'Hình ảnh sản phẩm'">
            </a>
            <div class="media-body">
              <a >
                <h4>{{ product?.productName }}</h4>
              </a>
              <h4>
                <span>
                  {{ product?.quantity }} x 
                  {{ product.prices[0]?.price | currency:'VND':'symbol':null:'vi-VN' }}
                </span>
              </h4>
            </div>
          </div>
          <div class="close-circle" (click)="removeItem(product)">
            <a href="javascript:void(0)"><i class="fa fa-times" aria-hidden="true"></i></a>
          </div>
        </li>
        <li>
          <div class="total">
            <h5>Tổng : <span>{{ getTotal | async | currency:'VND':'symbol':null:'vi-VN' }}</span></h5>
          </div>
        </li>
        <li>
          <div class="buttons">
            <a (click)="handleCartNavigate()" class="view-cart">Giỏ hàng</a>
            <a (click)="handleCheckoutOrder()" class="checkout">Đặt đơn</a>
          </div>
        </li>
      </ul>
    </li>
  </ul>
</div>
<app-register-customer-modal #registerCustomerModal></app-register-customer-modal>