import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Customers } from '@pickdi/common/lib/model/Customers';
import { Injectable } from "@angular/core";
import { RootState } from "../entities/state.entity";
import { Store } from "@ngrx/store";
import CustomersManager  from '@pickdi/common/lib/model/manager/CustomersManager';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Injectable()
export class CustomersService {
  constructor(
    private store: Store<RootState>,
    private loadingBar: LoadingBarService,
    ) { }
  async loadCustomerLogin(actionId): Promise<HttpStatus<Customers>> {
    this.loadingBar.start();
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = 'eCommerce/customer';
    let id = actionId.customerId;
    return customersManager.get(id);
  }
  async updateInfoCustomer(Customer) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer";
    return customersManager.update({"_id:": Customer._id} , Customer);
  }
  async changeTelCustomer(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/changeTelCustomer";
    return customersManager.update({_id: action._id} , action);
  }
  async changePasswordNew(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/changePassword";
    return customersManager.update({_id: action._id} , action);
  }
  async confirmChangeTelCustomer(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/confirmChangeTelCustomer";
    return customersManager.update({_id: action._id} , action);
  }
  async isForgotPassword(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/forgotPassword";
    return customersManager.update(action , action);
  }
  async isConfirmOTPForgotPassword(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/confirmOTPForgotPassword";
    return customersManager.update({tel: action?.tel } , action);
  }
  async isChangePasswordForgotPassword(action) : Promise<HttpStatus<Customers>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/changePasswordForgotPassword";
    return customersManager.update({tel: action?.tel } , action);
  }
  async onFeedbackProduct(action) : Promise<HttpStatus<any>>{
    const customersManager = new CustomersManager(null);
    customersManager.classInfo.endPoint = "eCommerce/customer/feedback";
    return customersManager.insert(action);
  }
}