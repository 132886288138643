import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { QuickViewComponent } from "../../modal/quick-view/quick-view.component";
import { CartModalComponent } from "../../modal/cart-modal/cart-modal.component";
import { Product } from "../../../classes/product";
import { ProductService } from "../../../services/product.service";
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-product-box-one',
  templateUrl: './product-box-one.component.html',
  styleUrls: ['./product-box-one.component.scss']
})
export class ProductBoxOneComponent implements OnInit {

  @Input() product: any;
  @Input() routerInput : string;
  @Input() currency: any = this.productService.Currency; // Default Currency
  @Input() thumbnail: boolean = false; // Default False 
  @Input() onHowerChangeImage: boolean = false; // Default False
  @Input() cartModal: boolean = false; // Default False
  @Input() loader: boolean = false;
  slug: string
  @ViewChild("quickView") QuickView: QuickViewComponent;
  @ViewChild("cartModal") CartModal: CartModalComponent;

  public ImageSrc : string

  constructor(private productService: ProductService, private route: ActivatedRoute, private toastrService: ToastrService, private loadingBar: LoadingBarService, private router: Router) { }

  ngOnInit(): void {
    if(this.loader) {
      setTimeout(() => { this.loader = false; }, 2000); // Skeleton Loader
    }
    this.route.paramMap.subscribe(params => {
      const slug = params.get('slug');
      this.slug = slug// In ra giá trị slug từ URL
    });
  }

  // Get Product Color
  // Color(variants) {
  //   const uniqColor = [];
  //   for (let i = 0; i < Object.keys(variants).length; i++) {
  //     if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
  //       uniqColor.push(variants[i].color)
  //     }
  //   }
  //   return uniqColor
  // }

  // Change Variants
  // ChangeVariants(color, product) {
  //   product.variants.map((item) => {
  //     if (item.color === color) {
  //       product.images.map((img) => {
  //         if (img.image_id === item.image_id) {
  //           this.ImageSrc = img.src;
  //         }
  //       })
  //     }
  //   })
  // }

  // Change Variants Image
  // ChangeVariantsImage(src) {
  //   this.ImageSrc = src;
  // }

  async addToCart(product: any) {
    // console.log(product);
   
    if (!product || !product?.productName || !product?._id) {
      return this.toastrService.error('Vui lòng thử lại')
    }
    
    // if (product?.shopSlug) {
    //   localStorage.setItem('shopSlug', JSON.stringify(product?.shopSlug))
    // } else {
    //   if(this.slug) {
    //     localStorage.setItem('shopSlug', JSON.stringify(this.slug))
    //   } else {
    //     if (localStorage.getItem('shopSlug')) localStorage.removeItem('shopSlug');
    //   }
    // }
    this.loadingBar.start();    
    const status = await this.productService.addToCart(product, this.slug);
    if(!status)  return 
    this.toastrService.success(`Đã thêm <b >${product?.productName && product.productName.substring(0, 15)+'...'}</b> vào giỏ hàng`)
  }

  addToWishlist(product: any) {
    this.productService.addToWishlist(product);
  }

  addToCompare(product: any) {
    this.productService.addToCompare(product);
  }

  onNavigate(slug: string){
    this.router.navigate([`${this.routerInput + slug}`])
  }
}
