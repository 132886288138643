import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-otp-layout',
  templateUrl: './otp-layout.component.html',
  styleUrls: ['./otp-layout.component.scss']
})
export class OtpLayoutComponent implements OnInit, OnDestroy {
  @Input() secondsRemaining: number = 59;
  @Input() tel: string;
  @Output() handleRefreshOTP: EventEmitter<any> = new EventEmitter();
  @Output() handleSubmitOTP: EventEmitter<any> = new EventEmitter();
  otp: string;
  interval: any;
  isInputDisabled: boolean = false;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    // inputStyles: {
    //   "font-size": 
    // }
  };
  secondsRemainingStart: number;
  constructor( private toastrService: ToastrService) {

  }
  ngOnInit(): void {
    if (!this.isInputDisabled) {
      // this.handleRefreshOTP();
      this.startCountdown();
    }
    this.secondsRemainingStart = this.secondsRemaining;
  }
  onOtpChange(otp) {
    this.otp = otp;
  }
  onRefreshOTP() {
    this.secondsRemaining = this.secondsRemainingStart;
    this.startCountdown();
    this.handleRefreshOTP.emit();
  }
  startCountdown() {
    this.isInputDisabled = true;
    if (this.interval) {
      clearInterval(this.interval); // Dừng interval trước đó nếu tồn tại
      this.interval = null;
    }
    this.interval = setInterval(() => {
      if (this.secondsRemaining > 0) {
        this.secondsRemaining--;
      } else {
        this.stopCountdown();
      }
    }, 1000);
  }
  stopCountdown() {
    if (this.interval) {
      clearInterval(this.interval); // Dừng interval trước đó nếu tồn tại
      this.interval = null;
    }
    this.isInputDisabled = false;
  }
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval); // Dừng interval trước đó nếu tồn tại
      this.interval = null;
    }
  }
  onSubmitOTP() {
    if (this.otp && this.otp.length === 6) {
      this.handleSubmitOTP.emit(this.otp);
      return;
    } 
    this.toastrService.error('Vui lòng nhập vào 6 mã code');
  }
  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
}
