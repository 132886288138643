import { Customers } from '@pickdi/common/lib/model/Customers';
import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, Input, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { confirmOtpVerification, getOtpVerification, login, registerMessage } from 'src/app/store/actions/auth.action';
import { RootState } from 'src/app/store/entities/state.entity';
import { Clipboard } from '@angular/cdk/clipboard';
import { changeTelCustomer, clearConfirmTelChange, clearStateCustomer, confirmChangeTelCustomer } from 'src/app/store/actions/customer.action';
import { ImageDownloadModalComponent } from '../download-image-modal/download-image-modal.component';
@Component({
  selector: 'app-otp-modal',
  templateUrl: './otp-modal.component.html',
  styleUrls: ['./otp-modal.component.scss'],
  providers: [DestroySubsribeService],
})
export class OtpModalComponent {
  @ViewChild("otpModal", { static: false }) OtpModal: TemplateRef<any>;
  @ViewChild("imageDownload", { static: false }) imageDownload:  ImageDownloadModalComponent;
  interval: any;
  qrcode: string;
  isInputDisabled: boolean = false;
  passwordConfirm: string;
  public closeResult: string;
  passwordResult: string;
  isChecktel: boolean = false;
  navigate : string;
  public modalOpen: boolean = false;
  otp: string;
  tel : string
  showOtpComponent = true;
  customerChangeTel: Customers;
  @ViewChild('ngOtpInput', { static: false}) ngOtpInput: any;
  config = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    // inputStyles: {
    //   "font-size": 
    // }
  };
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService, private toastrService: ToastrService,
    private store: Store<RootState>, private destroy: DestroySubsribeService,
    private clipboard: Clipboard
    ) { }

  ngOnInit(): void {
    this.store.select( state => state.customer.isConfirmTel)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(isConfirmTel => {
      if (!isConfirmTel) return;
      this.store.dispatch(clearConfirmTelChange());
      this.modalService.dismissAll();
    })
    this.store.select( state => state.authen.errorRegister)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(errorRegister => {
      if (!errorRegister) return;
      if (errorRegister?.message == "OK" || errorRegister?.code == 200) {
        if (errorRegister?.data && errorRegister?.data?.qrcode ) {
          this.qrcode = errorRegister?.data?.qrcode
        }
        this.modalOpen = false;
        let dataLogin = {
          tel: this.tel,
          password: `abc@${this.tel}`
        }
        if(this.navigate) this.store.dispatch(login(dataLogin));
        if(this.isChecktel) this.toastrService.success('xác minh số điện thoại thành công');
        
        this.store.dispatch(registerMessage(null));

        (this.tel && !this.isChecktel) ? this.passwordResult = `abc@${this.tel}` : this.passwordResult= null;
        this.tel = null;
        this.modalService.dismissAll();
        
      }
    })
  }
  copyTextToClipboard() {
    this.clipboard.copy(this.passwordResult);
    this.toastrService.success('Đã copy mật khẩu thành công');
  }
  handleOkPassword() {
    this.modalOpen = false;
    this.modalService.dismissAll();
    if (this.qrcode) {
      this.imageDownload.openModal(this.qrcode, 'Mã QR đăng nhập' , 'Lưu để đăng nhập nhanh', true, true, this.navigate);
    }

  }

  handleSubmitOTP(event) { //otp code
    if (event && event.length === 6) {
      let dataOTP = {
        tel: this.tel,
        OTP: event
      }
      
      if(!this.customerChangeTel) {
        this.store.dispatch(confirmOtpVerification(dataOTP))
      } else {
        let confirmTel = {
          _id: this.customerChangeTel?._id,
          password: this.passwordConfirm,
          ...dataOTP
        }
        this.store.dispatch(confirmChangeTelCustomer(confirmTel));

      }
      // this.toastrService.success('Xác thực thành công');
      return;
    } 
    
    this.toastrService.error('Vui lòng nhập vào 6 mã code');
  }
  handleRefreshOTP() {
      if (!this.customerChangeTel) {
        this.store.dispatch(getOtpVerification({ tel: this.tel }))
      } else {
        if (this.passwordConfirm) this.store.dispatch(changeTelCustomer({ _id: this.customerChangeTel?._id, tel: this.tel, password: this.passwordConfirm }))
      }
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
  openModal(tel: string, navigate?: string, isChecktel?: boolean, customerChangeTel?: Customers , passwordConfirm?: string) {
    this.modalOpen = true;
    this.passwordConfirm = passwordConfirm;
    this.navigate = navigate;
    this.isChecktel = isChecktel;
    this.customerChangeTel = customerChangeTel;
    this.tel = tel;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.OtpModal, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      if (this.passwordResult) {
        if (this.passwordResult) {
          this.modalService.dismissAll();
          if (this.qrcode) {
            this.imageDownload.openModal(this.qrcode, 'Mã QR đăng nhập' , 'Lưu để đăng nhập nhanh', true, true,  this.navigate);
          } 
        }
      }
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Get Product Color
  Color(variants) {
    const uniqColor = []
    for (let i = 0; i < Object.keys(variants).length; i++) {
      if (uniqColor.indexOf(variants[i].color) === -1 && variants[i].color) {
        uniqColor.push(variants[i].color)
      }
    }
    return uniqColor
  }
 
  setVal(val) {
    this.ngOtpInput.setValue(val);
  }

  toggleDisable(){
    if(this.ngOtpInput.otpForm){
      if(this.ngOtpInput.otpForm.disabled){
        this.ngOtpInput.otpForm.enable();
      }else{
        this.ngOtpInput.otpForm.disable();
      }
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
