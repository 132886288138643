<!-- Start Categories List -->
<div class="collection-collapse-block border-0" [class.open]="collapse">
    <h3 class="collapse-block-title" (click)="collapse = !collapse"><span class="title-menu">Danh mục món ăn</span></h3>
    <div class="collection-collapse-block-content">
        <ng-container *ngIf="menusList?.length > 0; else noCategorys">
            <div class="collection-brand-filter">
                <ul class="category-list">
                    <li *ngIf="menusList?.length > 1" [ngClass]="{'menu-select': !idMenuSelect}" (click)="handleChangeMenu(null)">
                        <a >
                            Tất cả sản phẩm
                        </a>
                    </li>
                    <li *ngFor="let menu of menusList"  [ngClass]="{'menu-select': idMenuSelect && idMenuSelect == menu._id}" (click)="handleChangeMenu(menu._id)">
                        <a >
                            {{ menu.menuName }}
                        </a>
                    </li>
                </ul>
            </div>
        </ng-container>
       
        <ng-template #noCategorys>
            <div class="d-flex justify-content-center">
                <p class="no-content">Không có dữ liệu</p>
            </div>
        </ng-template>
    </div>
</div>
<!-- End Categories List -->