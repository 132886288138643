import Constants from '@pickdi/common/lib/base/Constants';
import { Customers } from '@pickdi/common/lib/model/Customers';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, ROOT_EFFECTS_INIT } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Router } from '@angular/router';
import {   
    AuthActions, clearStateAuth, getOtpVerification, loadAuthen, loginMessage, loginSucess, registerMessage, registerSucess
} from '../actions/auth.action';
import { mergeMap, catchError, map, tap, takeUntil } from 'rxjs/operators';
import { from, of } from 'rxjs';
// import { hiddenProgress } from '../actions/progress.action';
// import { cleanDataShop } from '../actions/shop-info.action';
// import { loadDataAccessStaff, loadDataAccessStaffSuscess } from '../actions/staffs.action';
import { HandleError, RootState } from '../entities/state.entity';
import { AuthenService } from '../services/authen.service';
// import { DataService } from 'app/main/shared/shared.service';
import Staffs from '@pickdi/common/lib/model/Staffs';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { clearStateCustomer } from '../actions/customer.action';
import { Location } from '@angular/common';
import { WebSocketService } from 'src/app/shared/services/websocket.service';
import { clearShopDetail, onLoadInfoShopBySlug } from '../actions/shop.action';
import { onClearOrder } from '../actions/order.action';
import { clearMenu } from '../actions/menu.action';
import { onClearProduct } from '../actions/product.action';
import { addToCart, clearCart, onLoadCartList } from '../actions/cart.action';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { ProductService} from 'src/app/shared/services/product.service';
@Injectable()
export class AuthenEffect {

    private register$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.register),
        mergeMap((action: Action & Customers) => from(this.authenService.getData('eCommerce/customer/registerAccount' , action)) // dùng getData đối với nhưng api cần compare message
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop()
                    this.toastrService.success('Đã gửi mã đến số điện thoại') 
                    // value.tel ? getOtpVerification({tel: value.tel}) : ''
                    return [
                        registerMessage(null),
                    ]
                }),
                catchError((err) => (
                    this.loadingBar.stop(),
                    this.handleRegisterStatus(err?.error),
                    [
                        registerMessage({message: err?.error, code: err?.code, data: null})
                    ]
                  ))
            )
        )
    ));
    private getOtpVerification$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.getOtpVerification),
        mergeMap((action: Action & Customers) => from(this.authenService.getOtpVerification(action))
            .pipe(
                mergeMap(value => {
                    value.code == 200 ? this.toastrService.success('Đã gửi mã đến số điện thoại') : this.toastrService.error('Vui lòng thử lại') ;
                    this.loadingBar.stop()
                    
                    return [
                    ]
                }),
                catchError((err) => (
                    // this.data.getValueLogin('error'),
                    this.loadingBar.stop(),
                    [console.log(err)
                    ]
                ))
            )
        )
    ));
    private confirmOtpVerification$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.confirmOtpVerification),
        mergeMap((action: Action & Customers) => from(this.authenService.confirmOtpVerification(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop()
                    this.handleOtpStatus(value.entity);
                    return [
                        registerMessage({message: value?.message, code: value?.code, data: value?.entity}),
                        // registerSucess(value.entity),
                    ]
                }),
                catchError((err) => (
                    // this.data.getValueLogin('error'),
                    this.toastrService.error('Mã không chính xác hoặc đã hết hiệu lực'),
                    this.loadingBar.stop(),
                    [
                        registerMessage({message: err?.message, code: err?.code, data: null}),
                    ]
                ))
            )
        )
    ));
                
    private login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.login),
        mergeMap((action: Action & Customers) => from(this.authenService.getData('eCommerce/customer/login', action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    localStorage.setItem('id_token', value?.tokenWeb);
                    this.handleLoginStatus(value);
                    return [
                        loginSucess(value),
                        // hiddenProgress()];
                    ]
                }),
                catchError((err) => 
                    (
                        this.loadingBar.stop(),
                        this.handleLoginStatus(err.error),
                        [loginMessage({message: err?.error, code: err?.code})]
                    )
                )
            )
        )
    ));
    handleOtpStatus = (value: any):void => {
        switch (typeof value === 'object') {
            case true:
            this.toastrService.success('Đăng kí và xác minh tài khoản thành công');
            break

        default:
            this.toastrService.error(`Đăng kí và xác minh tài khoản không thành công`);
            break
        }
    }
    handleLoginStatus = (value: Customers):void => {
        switch (typeof value === 'object') {
            case true:
                
            this.toastrService.info(`Đã đăng nhập ! Chào <span class='f-w-700 primary-color'>${value?.fullName}</span>`);
            break

        default:
            if (value == 'PASSWORD INCORRECT!') {
                this.toastrService.info('Sai mật khẩu'); //cần sử lý message khi sai password hoặc cả 2 tại đây
            }else {
                this.toastrService.info('Số điện thoại hoặc mật khẩu không đúng');
            }
            break
        }
    }
   
    handleRegisterStatus = (message: any):void => {
        if (message == Constants.CUSTOMERS.USER_ALREADY_EXIST) {
            this.toastrService.info(Constants.CUSTOMERS.USER_ALREADY_EXIST);
        } 
        
    }
    private logout$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.logout),
        mergeMap(value => {
            this.onClearState();
            this.onClearStorage();
            this.webSocketServiceService.closeWebSocket();
            this.toastrService.success('Đăng xuất thành công');
            return [
            ]
        }),
        catchError((err) => (
            [
                console.log(err)
                
            ]
        ))
    ),  { dispatch: false });
    onClearState() {
        this.store.dispatch(clearStateAuth());
        this.store.dispatch(clearStateCustomer());
        this.store.dispatch(clearShopDetail());
        this.store.dispatch(onClearOrder());
        this.store.dispatch(clearMenu());
        this.store.dispatch(onClearProduct());
        this.store.dispatch(clearCart());
        this.productService.resetCart();

    }
    onClearStorage = () => {
        let wishList;
        if(localStorage.getItem('wishlistItems')) wishList = localStorage.getItem('wishlistItems');
        if(wishList) localStorage.setItem('wishlistItems', wishList);   
        this.router.navigate(['/pages/login']);
    }
    private loadAuthenInit$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.loadAuthenInit, ROOT_EFFECTS_INIT),
        map(() => loadAuthen(this.authenService.loadAuth()))
    ));


    private loadUnAuthen$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActions.loadUnAuthen),
        tap((action: Action & HandleError) => {
            this.toastrService.error('Vui lòng đăng nhập lại');
            this.onClearState();
            return [
                // logout(),

                this.router.navigate(['/logout'])
            ]
        })
    ));
   
    constructor(
        private actions$: Actions,
        private router: Router,
        private authenService: AuthenService,
        private toastrService: ToastrService,
        private loadingBar: LoadingBarService,
        private webSocketServiceService: WebSocketService,
        private productService: ProductService,
        private store: Store<RootState>,
        ){
        // private data: DataService) {
    }
}