export let PosConfigOptions =
    {       
        "dev":
        {
            "REST_API_HOST": "https://api.pickdi.com",
            "PORTAL_HOST": "https://pickdi.com",
            "WEB_HOST": "https://pickdi.com",
            "PERSISTANCE_DEFAULT": "REST",
            "AUTH0_APP_CLIENT_ID": "pBxmQyeNV2i10HdO2G2P7JS7NE2GDpq3",
            "AUTH0_DOMAIN": "tgn.auth0.com",
            'STRIPE_API_KEY': 'pk_test_3wq8iZnsFLzTduEDolx1MSyg',
            'STRIPE_DEFAULT_PLAN': 'plan_D9haf3SLo1C2pK',
            'STRIPE_CONNECT_CLIENT_ID' : 'ca_BAkjXSHC4wPVWzzCxB8VycWkufPX9WRj',
            'fwg.pickdy.pos.common': {
                "REST_API_HOST": "https://api.pickdi.com"
            },           
            // WEBSOCKET
            'WEB_SOCKET_HOST': 'ws://localhost',
            'WEB_SOCKET_PORT': 5002,
            "CACHE_ON":false,
            "SOCKET_API_HOST" : "wss://ws.pickdi.com",
            "SOCKET_PRINTER_SERVICE_HOST" : "wss://ws.pickdi.com"
        },       
        "shared_configuration":
        {
            "DEFAULT_NAMESPACE": "fwg.pickdy.pos.common"
        }
    };








