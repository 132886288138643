import { ToastrService } from 'ngx-toastr';
import { Menus } from '@pickdi/common/lib/model/Menus';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { MenusService } from '../services/menu.service';
import { MenusActions, onLoadMenusByShopSuccess } from '../actions/menu.action';
import { of, from } from 'rxjs';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { AuthenService } from '../services/authen.service';

@Injectable()
export class MenuEffect {

    constructor(private action$: Actions, private menuService: MenusService,  private loadingBar: LoadingBarService, private authenService: AuthenService, private toastrService :ToastrService) {
    }

    private onLoadMenusByShop$ = createEffect(() => this.action$.pipe(
        ofType(MenusActions.onLoadMenusByShop),
        mergeMap((action: Action & Menus) => from(this.menuService.onLoadMenusByShop(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    return [
                        onLoadMenusByShopSuccess(value.entity)
                    ]
                }),
                catchError(err =>(
                    this.loadingBar.stop(),
                    this.toastrService.error(err.message),
                    this.authenService.handleAuthError(err, null)
                    
                )
                ))
            )
        )
    );
}
