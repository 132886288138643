<header class="sticky" [ngClass]="class" [class.fixed]="stick && sticky">
  <div class="mobile-fix-option"></div>
  <div class="top-header" *ngIf="topbar">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <div class="header-contact">
            <ul>
              <li>Chọn đi! Còn lại Pickdi lo</li>
              <li><i class="fa fa-phone" aria-hidden="true"></i>Hotline: 0925 911 777</li>
            </ul>
          </div>
        </div>
        <div class="col-lg-6 text-end">
          <ul class="header-dropdown">
            <!-- <li class="compare">
              <a [routerLink]="['/shop/compare']"><i class="fa fa-random" aria-hidden="true"></i>So sánh</a>
            </li> -->
            <li class="mobile-wishlist">
              <a [routerLink]="['/pages/wishlist']"><i class="fa fa-heart" aria-hidden="true"></i>Yêu thích</a>
            </li>
            <li class="onhover-dropdown mobile-account" (mouseleave)="hiddenMenu = true" (mouseenter)="hiddenMenu = false" (click)="hiddenMenu = false">
              <i class="fa fa-user" aria-hidden="true"></i> {{infoCustomer && infoCustomer?.fullName ? infoCustomer?.fullName : 'Tài khoản' }}
              <ul class="onhover-show-div" [ngClass]="{'hidden-menu': hiddenMenu}">
                <li *ngIf="infoCustomer">
                  <a data-lng="en" (click)="onNavigate('profile')">
                    <i class="ti-id-badge"></i> Thông tin cá nhân
                  </a>
                </li>
                <li *ngIf="infoCustomer">
                  <a data-lng="en" (click)="onNavigate('view-oders')">
                   <i class="ti-package"></i> Đơn đặt hàng
                  </a>
                </li>
                <li *ngIf="!infoCustomer">
                  <a data-lng="en" (click)="onNavigate('login')">
                    <i class="ti-power-off"></i>Đăng nhập
                  </a>
                </li>
                <!-- <li>
                  <a data-lng="en" [routerLink]="['/pages/login']">
                    Đăng nhập
                  </a>
                </li> -->
                <li *ngIf="infoCustomer">
                  <a data-lng="es" (click)="handleLogout()">
                    <i class="ti-power-off"></i> Đăng xuất
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu">
          <div class="menu-left">
            <app-left-menu></app-left-menu>
            <div class="brand-logo">
              <a routerLink="/home" id="headerlogo">
                <img [src]="themeLogo" class="img-fluid" alt="logo">
              </a>
            </div>
          </div>
          <div class="menu-right pull-right">
            <div>
              <app-menu></app-menu>
            </div>
            <div>
              <app-settings></app-settings>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</header>
