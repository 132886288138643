import { DiscountEventRules } from '@pickdi/common/lib/model/DiscountEventRules';
import { Shops } from '@pickdi/common/lib/model/Shops';
import { createAction, props } from "@ngrx/store";

export const ShopActions = {
    
  onLoadInfoShopBySlug : '[Shop] on Load Shop by Slug',
  onLoadInfoShopBySlugSuccess: '[Shop] on Load Shop by Slug Success',
  onLoadAllShop : '[Shop] on Load All Shop',
  onLoadShopDetail: '[Shop] on Load Detail',
  onLoadAllShopSuccess : '[Shop] on Load All Shop Success',
  onLoadAllVoucherShop: '[Shop] on load all voucher shop',
  onLoadAllVoucherShopSuccess: '[Shop] on load all voucher shop Success',
  onFilterProductOrShop: '[Shop] on search shop or product',
  onFilterProductOrShopSuccess: '[Shop] on search shop or product Success',
  clearShopDetail: '[Shop] clear shop detail',
  };

  export const onLoadInfoShopBySlug = createAction(ShopActions.onLoadInfoShopBySlug,
    props<{slug: string}>()
  );
  
  export const onLoadShopDetail = createAction(ShopActions.onLoadShopDetail,
    props<{slug: string}>()
  );
  export const onLoadInfoShopBySlugSuccess = createAction(ShopActions.onLoadInfoShopBySlugSuccess,
    props<Shops>()
  );
  export const onLoadAllShop = createAction(ShopActions.onLoadAllShop , props<Shops>());
  export const onLoadAllShopSuccess = createAction(ShopActions.onLoadAllShopSuccess , props<Shops>());
  export const onLoadAllVoucherShop = createAction(ShopActions.onLoadAllVoucherShop,
    props<{slug: string}>()
  );
  export const onLoadAllVoucherShopSuccess = createAction(ShopActions.onLoadAllVoucherShopSuccess,
    props<{voucherList: DiscountEventRules[]} | any>()
  );

  export const onFilterProductOrShop = createAction(ShopActions.onFilterProductOrShop,
    props<{name: string}>()
  );
  export const onFilterProductOrShopSuccess = createAction(ShopActions.onFilterProductOrShopSuccess,
    props<{shops: Shops[]}>()
  );

  export const clearShopDetail = createAction(ShopActions.clearShopDetail);