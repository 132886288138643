import { Action, on } from "@ngrx/store";
import { createReducer } from "../utils/store.util";
import { OrdersState } from "../entities/state.entity";
import { onCreateOrderSuccess, onCreateOrder, onLoadTableByShop, onLoadTableByShopSuccess, onClearOrder, onLoadOrderList, onLoadOrderListSuccess } from "../actions/order.action";

const initialState : OrdersState = {
    orderItem: null,
    tableList: null,
    orderList: null
   };
   
   export const reducer = createReducer(
     initialState,
     on(onCreateOrder, (state, action) => ({
         ...state,
       })),
    on(onCreateOrderSuccess, (state, action) => ({
        ...state,
        orderItem: action,
    })),
    on(onLoadTableByShop, (state, action) => ({
        ...state,
      })),
   on(onLoadTableByShopSuccess, (state, action) => ({
       ...state,
       tableList: action,
   })),
   on(onLoadOrderList, (state, action) => ({
    ...state,
      })),
    on(onLoadOrderListSuccess, (state, action) => ({
      ...state,
      orderList: action?.oders,
    })),
   on(onClearOrder, (state, action) => ({
    ...state,
    orderItem: null,
})),
   );
   export function ordersReducer(state: OrdersState | undefined, action: Action) {
     return reducer(state, action);
   }