import { Shops } from '@pickdi/common/lib/model/Shops';
import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, Injectable, PLATFORM_ID, Inject, ViewChild, Renderer2 } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Observable, Subject, Subscription, debounceTime, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ProductService, state } from "../../services/product.service";
import { Product } from "../../classes/product";
import { LoginService } from '../../services/login.service';
import { RegisterCustomerModalComponent } from '../modal/register-customer-modal/register-customer-modal.component';
import { NavigationExtras, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from '../../services/destroySubscribe.service';
import { loadCustomerLogin } from 'src/app/store/actions/customer.action';
import * as _ from 'lodash';
import { onFilterProductOrShop } from 'src/app/store/actions/shop.action';
@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  @ViewChild("registerCustomerModal") registerCustomerModal: RegisterCustomerModalComponent;
  private debouncer: Subject<string> = new Subject<string>();
  public products: ProductItems[] = [];
  isOpen: boolean;
  public search: boolean = false;
  nameSearch: string = '';
  isLoadding: boolean = false;
  listSearchShopOrProduct: Shops[];
  customerId: string;
  hiddenMenu: boolean = true; // show | hide -> menu
  private subscription: Subscription;
  public languages = [{ 
    name: 'English',
    code: 'en'
  }, {
    name: 'French',
    code: 'fr'
  }];

  public currencies = [{
    name: 'Euro',
    currency: 'EUR',
    price: 0.90 // price of euro
  }, {
    name: 'Rupees',
    currency: 'INR',
    price: 70.93 // price of inr
  }, {
    name: 'Pound',
    currency: 'GBP',
    price: 0.78 // price of euro
  }, {
    name: 'Dollar',
    currency: 'USD',
    price: 1 // price of usd
  }]

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private renderer: Renderer2,
    private translate: TranslateService, private router: Router, private store: Store<RootState>, private destroy: DestroySubsribeService,
    public productService: ProductService, private loginService: LoginService) {
      this.subscription = this.productService.cartItems$.subscribe((items) => {
        this.products = items;
      });
      this.productService.cartItems.subscribe(response => {
        this.products = response
      });
      this.debouncer.pipe(debounceTime(500)).subscribe(value => {
        this.nameSearch = value;
        if(value === '') {
          this.listSearchShopOrProduct = [];
          return;
        }
        this.onFilterShopOrProduct();
      });

    
  }
  ngOnInit(): void {
    this.onLoadding();
    this.onLoadCustomerLogin();
      if(this.customerId) this.store.dispatch(loadCustomerLogin({customerId: this.customerId}));
      this.store.select( state => state.cart.listCart)
      .pipe(takeUntil(this.destroy.destroySub$))
      .subscribe(listCart => {
        if(listCart && listCart.length == 0 && !this.customerId) return;
         this.products = listCart;
         state.cart = _.cloneDeep(listCart);
      })
      
  }
  handleSearch(e:any) {
  
    this.debouncer.next(e.target.value);
  }
  onFilterShopOrProduct() {
    this.store.dispatch(onFilterProductOrShop({name: this.nameSearch}));
    this.store.select( state => state.shop.listSearchShopOrProduct)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(listSearchShopOrProduct => {
      if(listSearchShopOrProduct && listSearchShopOrProduct.length == 0) return;
      let shops = _.take(listSearchShopOrProduct, 4);
      this.listSearchShopOrProduct = shops;
    })
  }
  redirectToWithQueryParam() {
    if (this.listSearchShopOrProduct.length < 0 && this.nameSearch === '')  return;
      const queryParams: NavigationExtras = {
        queryParams: { q: this.nameSearch }
      };
      this.search = false;
      const route = '/pages/search-shop-or-product';
      this.router.navigate([route], queryParams);  
      
  }
   handleCheckoutOrder() {
    let checkLogin = this.loginService.onCheckLogin(false);
    let timeout = setTimeout(() => {
      this.hiddenMenu= true;
      clearTimeout(timeout)
    }, 200);
    if (!checkLogin) {
      this.registerCustomerModal.openModal('/pages/checkout');
    } else {
      this.router.navigate(['/pages/checkout']);
    }
    
  }
  onLoadding() {
    this.store.select( state => state.loadding.isLoadding)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(isLoadding => {
      if(!this.search) return
      this.isLoadding = isLoadding;
    })
  }
  onLoadCustomerLogin() {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.customerId = null;
      this.customerId = customerLogin._id;
    })
  }
  checkOpeningHours(openingTime:Date, closingTime:Date) {
    
    const now = new Date();

    if (now >= openingTime && now < closingTime) {
      return true;
    } else {
      return false;
    }
  }
  setOpeningAndClosingTime(openingTimeString :string ,closingTimeString :string) {
    if (!openingTimeString || !closingTimeString) return false
    let openingTime = new Date(); // Thời gian mở cửa
    let closingTime= new Date(); // Thời gian đóng cửa

    const [openingHours, openingMinutes] = openingTimeString.split(':').map(Number);
    const [closingHours, closingMinutes] = closingTimeString.split(':').map(Number);

    openingTime.setHours(openingHours, openingMinutes, 0);
    closingTime.setHours(closingHours, closingMinutes, 0);
    return this.checkOpeningHours(openingTime, closingTime);
  }
  handleCartNavigate() {
    this.router.navigate(['pages/cart']);
    let timeout = setTimeout(() => {
      this.hiddenMenu= true;
      clearTimeout(timeout)
    }, 200);
  }
  searchToggle(){
    this.search = !this.search;
  }
  changeLanguage(code){
    if (isPlatformBrowser(this.platformId)) {
      this.translate.use(code)
    }
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product, this.customerId);
  }

  changeCurrency(currency: any) {
    this.productService.Currency = currency
  }

}
