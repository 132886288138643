import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Product } from "../../../classes/product";
import { ProductService } from '../../../../shared/services/product.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ConfirmModalComponent } from '../confirm-modal/confirm-modal.component';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
  styleUrls: ['./quick-view.component.scss']
})
export class QuickViewComponent implements OnInit, OnDestroy  {

  @Input() product: ProductItems;
  @Input() slug: string;
  @Input() currency: any;  
  @ViewChild("quickView", { static: false }) QuickView: TemplateRef<any>;
  @ViewChild("confirmModal") ConfirmModal: ConfirmModalComponent;
  public closeResult: string;
  public ImageSrc: string;
  public counter: number = 1;
  customerId: string;
  public modalOpen: boolean = false;
  productSelectAddToCart: ProductItems;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService, private store: Store<RootState>, private destroy: DestroySubsribeService, private toastrService: ToastrService, private loadingBar: LoadingBarService,) { }

  ngOnInit(): void {
    this.onLoadCustomerLogin();
  }
  onLoadCustomerLogin() {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.customerId = null;
      this.customerId = customerLogin._id;
    })
  }
  openModal(product?:ProductItems) {
    this.counter = 1;
    if (!this.product) this.product = product;
    this.modalOpen = true;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.QuickView, { 
        size: 'lg',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'Quickview' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  // Increament
  increment() {
    this.counter++ ;
  }

  // Decrement
  decrement() {
    if (this.counter > 1) this.counter-- ;
  }

  // Add to cart
  async addToCart(product: any, resetCart?: boolean) {
    this.productSelectAddToCart= null;
    product.quantity = this.counter || 1;
    if (localStorage.getItem('shopSlug')) {
      if (JSON.parse(localStorage['shopSlug']) != this.slug) {
         this.ConfirmModal.openModal('Chọn cửa hàng khác sẽ cài lại giỏ hàng!');
         this.productSelectAddToCart = product;
         return
       } 
     }
     if (!product || !product?.productName || !product?._id) {
       return this.toastrService.error('Vui lòng thử lại')
     }
     this.loadingBar.start();    
     const status = await this.productService.addToCart(product,this.customerId, this.slug, resetCart);
     if(!status)  return 
     this.toastrService.success(`Đã thêm <b >${product?.productName && product.productName.substring(0, 15)+'...'}</b> vào giỏ hàng`)
    this.modalService.dismissAll();
     
    }
    async resetCart() {
      const status = await this.productService.resetCart();
      return status
  }
    ngAfterViewInit() {
      this.ConfirmModal.resultConfirm.subscribe((result) => {
        if (result) {
            if (this.resetCart()) {
              return this.addToCart(this.productSelectAddToCart, true);
            } else {
              return this.toastrService.info('Cài lại giỏ hàng không thành công');
            }
        }
      });
    }
  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
