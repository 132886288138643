import { Feedbacks } from '@pickdi/common/lib/model/Feedbacks';
import { Customers } from '@pickdi/common/lib/model/Customers';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { mergeMap, catchError, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { AuthenService } from '../services/authen.service';
import { of, from } from 'rxjs';
import { CustomerActions, clearStateCustomer, loadCustomerLoginSuccess , loadCustomerLogin, changePasswordNewSuccess, confirmChangeTelCustomerSuccess, isConfirmPassword, isForgotPasswordSuccess, isConfirmOTPForgotPasswordSuccess, isChangePasswordForgotPasswordSuccess } from '../actions/customer.action';
import { CustomersService } from '../services/customer.service';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ToastrService } from 'ngx-toastr';
import * as e from 'express';
import { onLoadCartList } from '../actions/cart.action';
@Injectable()
export class CustomerEffect {
    
                
    private loadCustomerLogin$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.loadCustomerLogin),
        mergeMap((action: Action & Customers) => from(this.customerService.loadCustomerLogin(action))
            .pipe(
                mergeMap(value => {
                    this.loadingBar.stop();
                    return [
                        value.entity ? loadCustomerLoginSuccess(value.entity) : clearStateCustomer(),
                        onLoadCartList({carts: value?.entity?.cart})
                        // hiddenProgress()];
                    ]
                }),
                catchError(err => {
                    this.authenService.handleAuthError(err, of());
                    this.loadingBar.stop();
                    return [
                        clearStateCustomer(),
                    ]
                  
                }  
                
                ))
            )
        )
    );

    private updateInfoCustomer$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.updataInfoCustomer),
        mergeMap((action : Action & Customers) => from(this.customerService.updateInfoCustomer(action))
         .pipe(
            mergeMap(value =>{
                value.code == 200 ? this.toastrService.success('Đã cập nhật thông tin cá nhân') : this.toastrService.error('Vui lòng thử lại') ;
                return [ 
                    loadCustomerLogin({customerId : value.entity._id})
                ]
            })
         )
        )  
    ))

    private changeTelCustomer$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.changeTelCustomer),
        mergeMap((action : Action & Customers) => from(this.customerService.changeTelCustomer(action))
         .pipe(
            mergeMap(value =>{
                value.code == 200 ? this.toastrService.success('Đã gửi mã đến số điện thoại') : this.toastrService.error('Vui lòng thử lại') ;
                this.loadingBar.stop()
                return [ 
                    isConfirmPassword({isConfirm: true})
                ]
            }),
            catchError(err =>(
                this.toastrService.error(err.message),
                this.loadingBar.stop(),
                this.authenService.handleAuthError(err, of()),
                [ isConfirmPassword({isConfirm: false})]
            )
            )
         )
        )  
    ))
    private changePasswordNew$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.changePasswordNew),
        mergeMap((action : Action & Customers) => from(this.customerService.changePasswordNew(action))
         .pipe(
            mergeMap(value =>{
                value.code == 200 ? this.toastrService.success('Thay đổi mật khẩu thành công') : this.toastrService.error('Thay đổi mật khẩu không thành công') ;
                return [ 
                    value.entity ? changePasswordNewSuccess(value.entity) : '',
                    value.entity ? loadCustomerLogin({customerId : value.entity._id}) : ''
                ]
            }),
            catchError(err =>(
                console.log(err),
                this.loadingBar.stop(),
                this.authenService.handleAuthError(err, of())
            )
            )
         )
        )  
    ))       
    private confirmChangeTelCustomer$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.confirmChangeTelCustomer),
        mergeMap((action : Action & Customers) => from(this.customerService.confirmChangeTelCustomer(action))
         .pipe(
            mergeMap(value =>{                 
                value.code == 200 ? this.toastrService.success('Thay đổi số điện thoại thành công!') : this.toastrService.error('Mã không chính xác hoặc đã hết hiệu lực') ;
                return [ 
                    loadCustomerLoginSuccess(value.entity),
                    confirmChangeTelCustomerSuccess(true),
                ]
            }),
            catchError((err) => (
                this.toastrService.error(err?.message),
                this.loadingBar.stop(),
                [
                    // registerMessage({message: err?.message, code: err?.code}),
                ]
            ))
         )
        )  
    ))      

    private isForgotPassword$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.isForgotPassword),
        mergeMap((action : Action & Customers) => from(this.customerService.isForgotPassword(action))
         .pipe(
            mergeMap(value =>{        
                this.toastrService.success('Đã gửi mã đến số điện thoại');
                return [ 
                    isForgotPasswordSuccess({isForgotPassword: value})
                ]
            }),
            catchError((err) => (
                this.toastrService.error(err?.message),
                this.loadingBar.stop(),
                [
                    // registerMessage({message: err?.message, code: err?.code}),
                ]
            ))
         )
        )  
    ))   
    private isConfirmOTPForgotPassword$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.isConfirmOTPForgotPassword),
        mergeMap((action : Action & Customers) => from(this.customerService.isConfirmOTPForgotPassword(action))
         .pipe(
            mergeMap(value =>{    
                return [ 
                    isConfirmOTPForgotPasswordSuccess({isConfirmOTPForgotPassword: value.code === 200 ? true : false})
                ]
            }),
            catchError((err) => (
                this.toastrService.error(err?.message),
                this.loadingBar.stop(),
                [
                    isConfirmOTPForgotPasswordSuccess({isConfirmOTPForgotPassword: false})
                ]
            ))
         )
        )  
    ))     
    private isChangePasswordForgotPassword$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.isChangePasswordForgotPassword),
        mergeMap((action : Action & Customers) => from(this.customerService.isChangePasswordForgotPassword(action))
         .pipe(
            mergeMap(value =>{    
                return [ 
                    isChangePasswordForgotPasswordSuccess({isChangePasswordForgotPassword: value.code === 200 ? true : false})
                ]
            }),
            catchError((err) => (
                this.toastrService.error(err?.message),
                this.loadingBar.stop(),
                [
                    isChangePasswordForgotPasswordSuccess({isChangePasswordForgotPassword: false})
                ]
            ))
         )
        )  
    ))
    private onFeedbackProduct$ = createEffect(() => this.action$.pipe(
        ofType(CustomerActions.onFeedbackProduct),
        mergeMap((action : Action & Feedbacks) => from(this.customerService.onFeedbackProduct(action))
         .pipe(
            mergeMap(value =>{        
                this.toastrService.success('Đánh giá thành công! Cảm ơn đánh giá từ bạn.');
                return [ 
                ]
            }),
            catchError((err) => (
                this.toastrService.error(err?.message),
                this.loadingBar.stop(),
                [
                    // registerMessage({message: err?.message, code: err?.code}),
                ]
            ))
         )
        )  
    ),  { dispatch: false })                     

    constructor(private action$: Actions, private toastrService: ToastrService, private authenService: AuthenService, private customerService: CustomersService,  private loadingBar: LoadingBarService) { }
}