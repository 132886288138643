import { clearConfirmTelChange, confirmChangeTelCustomer, isConfirmPassword } from './../actions/customer.action';
import { Action, on } from "@ngrx/store";
import { createReducer } from "../utils/store.util";
import { CartsState, CustomersState } from "../entities/state.entity";
import { changePasswordNew, changePasswordNewSuccess, changeTelCustomer, clearStateCustomer, confirmChangeTelCustomerSuccess, loadCustomerLogin, loadCustomerLoginSuccess , updataInfoCustomer } from "../actions/customer.action";
import { addToCart, addToCartSuccess, clearCart, onDeleteCartItem, onDeleteCartItemSuccess, onLoadCartList, onUpdateCart, onUpdateCartSuccess } from '../actions/cart.action';

const initialState : CartsState = {
   listCart: [],
  };
  
  export const reducer = createReducer(
    initialState,
    on(onLoadCartList, (state, action) => ({
        ...state,
        listCart: action.carts,
      })),
        on(addToCart, (state, action) => ({
            ...state,
        })),
        on(addToCartSuccess, (state, action) => ({
            ...state,
            listCart: action.carts,
        })),
        on(onUpdateCart, (state, action) => ({
            ...state,
        })),
        on(onUpdateCartSuccess, (state, action) => ({
            ...state,
            listCart: action.carts,
        })),
        on(onDeleteCartItem, (state, action) => ({
            ...state,
        })),
        on(onDeleteCartItemSuccess, (state, action) => ({
            ...state,
            listCart: action.carts,
        })),
      on(clearCart, (state, action) => ({
        ...state,
        listCart: [],
      })),
     
  );
  export function cartReducer(state: CartsState | undefined, action: Action) {
    return reducer(state, action);
  }