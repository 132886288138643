import { Shops } from '@pickdi/common/lib/model/Shops';
import { Component, OnInit, Input, ViewChild, AfterViewInit, HostListener } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';

@Component({
  selector: 'app-shop-card',
  templateUrl: './shop-card.component.html',
  styleUrls: ['./shop-card.component.scss']
})
export class ShopCardComponent implements OnInit {
  @Input() shop: Shops;
  constructor(private productService: ProductService,private destroy: DestroySubsribeService, private route: ActivatedRoute, private toastrService: ToastrService,private store: Store<RootState>, private loadingBar: LoadingBarService, private router: Router) { 

  }
  
  ngOnInit(): void {
      
  }
  
}
