<ng-template class="theme-modal" #registerCustomerModal let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body p-3">
      <div class="row">
        <div class="col-lg-12">
          <h3 class="d-flex justify-content-center">Xác thực đặt hàng</h3>
          <div
          class="e-commerce-input custom-control custom-checkbox collection-filter-checkbox mb-2"
        >
          <label class="content btn-default-font" for="account"> Người mới đến quán</label>
          <input
            type="checkbox"
            (change)="handleCheckNewCustomer($event)"
            [checked]="checkCustomerNew"
            id="account"
            class="custom-control-checkbox"
          />
        </div>
          <div class="theme-card">
            <form
              class="theme-form"
              [formGroup]="loginForm"
              *ngIf="!checkCustomerNew"
            >
              <div class="tel">
                <e-commerce-input
                  [label]="'Số điện thoại'"
                  [formName]="'tel'"
                  [autoComplete]="true"
                  [required]="true"
                  [pattern]="telPattern"
                  [inputType]="'phoneNumber'"
                  [preValue]="loginForm.value['tel']"
                  [formControl]="loginForm?.controls.tel"
                  [inputRequired]="validateForm(loginForm, 'tel')"
                  [inputValid]="loginForm.controls.tel.errors?.required"
                >
                  <div class="regexForm">
                    <ng-container *ngIf="regexFormPattern(loginForm, 'tel')">
                      <p class="text-danger text-left">
                        Số điện thoại không hợp lệ
                      </p>
                    </ng-container>
                  </div>
                </e-commerce-input>
              </div>
              <div class="mt-3">
                <e-commerce-input
                  [label]="'Mật khẩu'"
                  [formName]="'password'"
                  [required]="true"
                  [autoComplete]="true"
                  [typeInput]="'password'"
                  [preValue]="loginForm.value['password']"
                  [formControl]="loginForm?.controls.password"
                  [inputRequired]="validateForm(loginForm, 'password')"
                  [inputValid]="loginForm.controls.password.errors?.required"
                >
                  <div class="regexForm">
                    <ng-container
                      *ngIf="loginForm.controls.password.errors?.minlength"
                    >
                      <p class="text-danger text-left">
                        Mật khẩu tối thiểu 6 kí tự
                      </p>
                    </ng-container>
                    <ng-container
                      *ngIf="loginForm.controls.password.errors?.maxlength"
                    >
                      <p class="text-danger text-left">
                        Mật khẩu tối đa 32 kí tự
                      </p>
                    </ng-container>
                  </div>
                </e-commerce-input>
              </div>
            </form>
            <form
              class="theme-form"
              [formGroup]="registerForm"
              *ngIf="checkCustomerNew"
            >
              <div class="full-name">
                <e-commerce-input
                  [label]="'Họ tên của bạn'"
                  [formName]="'fullName'"
                  [required]="true"
                  [preValue]="registerForm.value['fullName']"
                  [formControl]="registerForm?.controls.fullName"
                  [inputRequired]="validateForm(registerForm, 'fullName')"
                  [inputValid]="registerForm.controls.fullName.errors?.required"
                >
                  <div class="regexForm">
                    <ng-container
                      *ngIf="registerForm.controls.fullName.errors?.maxlength"
                    >
                      <p class="text-danger text-left">Tên tối đa 35 kí tự</p>
                    </ng-container>
                  </div>
                </e-commerce-input>
              </div>
              <div class="mt-3">
                <e-commerce-input
                  [label]="'Số điện thoại'"
                  [formName]="'tel'"
                  [pattern]="telPattern"
                  [required]="true"
                  [preValue]="registerForm.value['tel']"
                  [inputType]="'phoneNumber'"
                  [formControl]="registerForm?.controls.tel"
                  [inputRequired]="validateForm(registerForm, 'tel')"
                  [inputValid]="registerForm.controls.tel.errors?.required"
                >
                  <div class="regexForm">
                    <ng-container *ngIf="regexFormPattern(registerForm, 'tel')">
                      <p class="text-danger text-left">
                        Số điện thoại không hợp lệ
                      </p>
                    </ng-container>
                  </div>
                </e-commerce-input>
              </div>
            </form>
           
            <button class="btn btn-outline mt-3 w-100" (click)="handleSubmit()">
              Xác nhận
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-otp-modal #otpModal></app-otp-modal>
<app-confirm-modal #confirmCart></app-confirm-modal>