import  Tables  from '@pickdi/common/lib/model/Tables';
import { createAction, props } from "@ngrx/store";
import { WebOrders } from '@pickdi/common/lib/model/WebOrders';
import { WebOrderReportCriterial } from '@pickdi/common/lib/model/WebOrderReportCriterial';
export const OrdersAction = {
    onCreateOrder: '[Order] create order',
    onClearOrder: '[Order] clear order',
    onCreateOrderSuccess: '[Order] create order success',
    onLoadTableByShop : '[Order] on Load Table By Shop',
    onLoadTableByShopSuccess : '[Order] on Load Table By Shop success',
    onLoadOrderList: '[Order] on Load Order List',
    onLoadOrderListSuccess: '[Order] on Load Order List success',
  };


  export const onCreateOrder = createAction(OrdersAction.onCreateOrder,
    props<WebOrders>()
  );
  export const onClearOrder = createAction(OrdersAction.onClearOrder);
  export const onCreateOrderSuccess = createAction(OrdersAction.onCreateOrderSuccess,
    props<WebOrders | null>()
    );

  export const onLoadTableByShop = createAction(OrdersAction.onLoadTableByShop
    ,props<{shopId: string, tableType: string}>()
    );
  export const onLoadTableByShopSuccess = createAction(OrdersAction.onLoadTableByShopSuccess,
    props<Tables>()
    );
    export const onLoadOrderList = createAction(OrdersAction.onLoadOrderList
      ,props<WebOrderReportCriterial>()
      );
    export const onLoadOrderListSuccess = createAction(OrdersAction.onLoadOrderListSuccess,
      props<{oders: WebOrders[]} | any>()
      );