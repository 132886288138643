<ng-template class="theme-modal" #orderViewDetail let-modal>
  <div class="modal-content quick-view-modal">
    <div class="modal-body">
      <div class="col-12 title">
        <p>
          Đơn của bạn tại
          <span class="shop-name">{{ orderDetail?.shopName }}</span>
        </p>
        <p>
            Địa chỉ: 
            <span class="shop-name"> {{orderDetail?.address ? orderDetail?.address + ', ' : ''}}{{orderDetail?.district ? orderDetail?.district + ', ' : ''}}{{orderDetail?.city}}</span>
          </p>
      </div>
      <div class="info">
        <div class="row">
          <div class="col-md-4 col-sm-12 col-xs-12">
            <span id="heading">Ngày đặt hàng</span><br />
            <span id="details">{{orderDetail?.orderDateTime | date: 'dd/MM/yyyy - HH:mm':'UTC' }}</span>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
            <span id="heading">Mã đơn hàng</span><br />
            <span id="details">{{orderDetail?.orderNumber}}</span>
          </div>
          <div class="col-md-4 col-sm-12 col-xs-12 pull-right">
            <span id="heading">Loại hàng</span><br />
            <span id="details">{{orderDetail?.orderType}}</span>
          </div>
        </div>
      </div>
      <div class="pricing mobile">
        <div class="row tb-body" *ngFor="let product of orderDetail?.depositRules">
          <div class="col-md-7 col-sm-12 col-xs-12">
            <span id="name">{{product?.productName}} x{{product?.quantity}}</span>
           
          </div>
          <div class="col-md-5 col-sm-12 col-xs-12 d-flex">
            <span id="price">{{product?.priceOrder | currency : "VND" : "symbol" : null : "vi-VN"}}</span>
            <span class="tooltip-block block-evaluate" >
              <button class="btn btn-solid evaluate" *ngIf="!product?.isFeedback && orderDetail.status == WEB_ORDER.COMPLETE" (click)="handleEvaluate(product)">Đánh giá</button>
              <!-- <i class="fa fa-hand-o-left evaluate" (click)="handleEvaluate(product)" aria-hidden="true"></i> -->
          </span>
          </div>
        </div>
      </div>
      <div class="pricing">
        <div class="row th-table">
          <div class="col-7">
            <span id="name">Mặt hàng</span>
          </div>
          <div class="col-2">
            <span id="price">Số lượng</span>
          </div>
          <div class="col-3">
            <span id="price">Đơn giá</span>
          </div>
        </div>
        <div class="row tb-body" *ngFor="let product of orderDetail?.depositRules">
          <div class="col-7">
            <span id="name">{{product?.productName}}</span>
          </div>
          <div class="col-2">
            <span id="price">{{product?.quantity}}</span>
          </div>
          <div class="col-3">
            <span id="price">{{product?.priceOrder | currency : "VND" : "symbol" : null : "vi-VN"}}</span>
            <span class="tooltip-block block-evaluate">
              <!-- <i class="fa fa-hand-o-left evaluate"  (click)="handleEvaluate(product)" aria-hidden="true"></i> -->
              <button class="btn btn-solid evaluate" *ngIf="!product?.isFeedback && orderDetail.status == WEB_ORDER.COMPLETE" (click)="handleEvaluate(product)">Đánh giá</button>
          </span>
          </div>
        </div>
        
      </div>
      <div class="total mt-2">
        <div class="row">
          <div class="col-9"><span class="total-order fw-bolder">Tổng giá</span></div>
          <div class="col-3">{{orderDetail?.priceDeposit | currency : "VND" : "symbol" : null : "vi-VN"}}</div>
        </div>
        <!-- <div class="row">
            <div class="col-9"><span class="total-order fw-bolder">Đặt cọc</span></div>
            <div class="col-3">{{orderDetail?.priceOrder | currency : "VND" : "symbol" : null : "vi-VN"}}</div>
          </div> -->
      </div>
     

      <ng-container *ngIf="orderDetail.status == WEB_ORDER.CANCEL_ORDER else noCancel">
        <div class="progress-track">
          <div class="tracking mt-2">
            <div class="title">Trạng thái đơn hàng</div>
          </div>
          <ul id="progressbar">
              <li class="step0 active " id="step1" style="width: 30%;"><span style="margin-left: -5px;" >Đặt đơn</span></li>
              <li class="step0 active cancel" id="step2" style="width: 30%;"><span >{{STATUS_ORDER.orderCancel}}</span></li>
          </ul>
        </div>
      </ng-container>
      <ng-template #noCancel>
        <div class="progress-track">
          <div class="tracking mt-2">
            <div class="title">Trạng thái đơn hàng</div>
          </div>
          <ul id="progressbar">
              <li class="step0" [ngClass]="{'active': checkStepStatus(WEB_ORDER.WAIT)}" id="step1" ><span >{{STATUS_ORDER.wait}}</span> <p class="time-left" *ngIf="orderDetail.status == WEB_ORDER.WAIT">{{getElapsedTime()}}</p></li>
              <li class="step0 text-center" [ngClass]="{'active': checkStepStatus(WEB_ORDER.RECEIVED)}" id="step2" style="width: 22%;"><span >{{STATUS_ORDER.received}}</span> <p class="time-center" *ngIf="orderDetail.status == WEB_ORDER.RECEIVED || orderDetail.status == WEB_ORDER.RECEIVED_KICHEN">{{getElapsedTime()}}</p></li>
              <li class="step0 text-center" [ngClass]="{'active': checkStepStatus(WEB_ORDER.IN_PROCESS_KICHEN)}" id="step3" style="width: 22%;"><span >{{STATUS_ORDER.inProcessKichen}}</span> <p class="time-center" *ngIf="orderDetail.status == WEB_ORDER.IN_PROCESS_KICHEN">{{getElapsedTime()}}</p></li>
              <li class="step0 text-center" [ngClass]="{'active': checkStepStatus(WEB_ORDER.COMPLETE_KICHEN)}" id="step4" style="width: 22%;"><span >{{STATUS_ORDER.kichenComplete}}</span> <p class="time-center" *ngIf="orderDetail.status == WEB_ORDER.COMPLETE_KICHEN">{{getElapsedTime()}}</p></li>
              <li class="step0 text-right" id="step5" [ngClass]="{'active':  checkStepStatus(WEB_ORDER.COMPLETE)}"><span >{{STATUS_ORDER.complete}}</span> <p class="time-right" *ngIf="orderDetail.status == WEB_ORDER.COMPLETE">{{orderDetail?.timeChangeStatus | date: 'HH:mm':'UTC' }}p</p></li>
       
          </ul>
        </div>
      </ng-template>
      <div class="footer">
        <div class="row">
          <div class="col-12">
            <img class="img-fluid" src="assets/images/snapedit_1695888244747(1).png" />
            Bạn cần giúp đỡ? <a> Liên hệ chúng tôi</a>
          </div>
          <div class="col-12 d-flex justify-content-center mt-2">
            <button class="btn btn-solid" (click)="modal.dismiss('Cross click')">Đóng</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<app-evaluate-modal #evaluateProduct></app-evaluate-modal>