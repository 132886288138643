import { Menus } from '@pickdi/common/lib/model/Menus';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../classes/product';
import { ProductService } from '../../services/product.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @Input() menusList: Menus[] = [];
  
  @Output() changeMenu: EventEmitter<any> = new EventEmitter();
  @Input() idMenuSelect: string;
  public products: Product[] = [];
  public collapse: boolean = true;

  constructor(public productService: ProductService, private route: ActivatedRoute) { 
  }
  

  ngOnInit(): void {
    
  }
  handleChangeMenu(idMenu: string) {
  
    if (!idMenu) {
      this.changeMenu.emit(null)
      return this.idMenuSelect = null;
    } 
    this.changeMenu.emit(idMenu) 
    this.idMenuSelect = idMenu;
  }
}
