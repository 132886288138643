import Constants  from '@pickdi/common/lib/base/Constants';
import WebOrders  from '@pickdi/common/lib/model/WebOrders';
import { ProductItems } from '@pickdi/common/lib/model/ProductItems';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '../../../services/product.service';
import { STATUS_ORDER } from 'src/app/shared/enum/share.enum';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { RootState } from 'src/app/store/entities/state.entity';
import { onFeedbackProduct } from 'src/app/store/actions/customer.action';
import { takeUntil } from 'rxjs';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-evaluate-modal',
  templateUrl: './evaluate-product.component.html',
  styleUrls: ['./evaluate-product.component.scss']
})
export class EvaluateProductComponent implements OnInit, OnDestroy  {
  @ViewChild("evaluateProduct", { static: false }) evaluateProduct: TemplateRef<any>;
  public closeResult: string;
  commentForm: FormGroup;
  productFeebback: ProductItems;
  orderFeedback: WebOrders;
  raitingSelect: number;
  public modalOpen: boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private router: Router, private destroy: DestroySubsribeService, private store: Store<RootState>,  private formBuilder: FormBuilder, private toastrService: ToastrService,
     private modalService: NgbModal,
    public productService: ProductService) { }

  ngOnInit(): void {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.router.navigate(['pages/login']);;
    })
    this.commentForm = this.createfeedbackForm();
  }
  createfeedbackForm(): FormGroup {
    return this.formBuilder.group({
      comment: [null , [Validators.maxLength(500)]],
    })
  }
  handleSubmitEvaluate() {
    if (this.raitingSelect > 0) {
      this.store.select( state => state.customer.customerLogin)
      .pipe(takeUntil(this.destroy.destroySub$))
      .subscribe(customerLogin => {
        if (!customerLogin && !this.productFeebback) return this.router.navigate(['pages/login']);
        let dataFeedback = {
          customerId : customerLogin?._id,
          customerName: customerLogin?.fullName,
          productId: this.productFeebback.productItemId,
          productName: this.productFeebback.productName,
          comment: this.commentForm?.value?.comment,
          orderWebId: this.orderFeedback._id,
          rating: this.raitingSelect
        }
        this.store.dispatch(onFeedbackProduct(dataFeedback));
        this.modalService.dismissAll();
      })
      return;
    }
    this.toastrService.info('Vui lòng đánh giá sản phẩm!');
  }
  openModal(productFeebback: ProductItems, order: WebOrders) {
    this.raitingSelect = 0;
    this.orderFeedback = order;
    this.productFeebback = productFeebback;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.evaluateProduct, { 
        size: 'md',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'OrderViewDetail' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
      this.modalService.hasOpenModals
    }
  }

}

