import ProductItems from '@pickdi/common/lib/model/ProductItems';
import Feedbacks from '@pickdi/common/lib/model/Feedbacks';
import { createAction, props } from "@ngrx/store";

export const ProductAction = {
    onLoadAllNewProduct : '[Product] on Load All New Product',
    onLoadAllNewProductSuccess: '[Product] on Load All New Product Success',
    onLoadAllPromotionProduct : '[Product] on Load All Promotion Product',
    onLoadAllPromotionProductSuccess : '[Product] on Load All Promotion Success',
    onClearProduct: '[Product] on Clear Product',
}

export const onLoadAllNewProduct = createAction(ProductAction.onLoadAllNewProduct , 
    props<{productTag : string}>()
);
export const onLoadAllNewProductSuccess = createAction(ProductAction.onLoadAllNewProductSuccess ,
    props<ProductItems>()
);
export const onLoadAllPromotionProduct = createAction(ProductAction.onLoadAllPromotionProduct ,
    props<{productTag : string}>()  
);
export const onLoadAllPromotionProductSuccess = createAction(ProductAction.onLoadAllPromotionProductSuccess ,
    props<ProductItems>()
)
export const onClearProduct = createAction(ProductAction.onClearProduct);