import { Customers } from '@pickdi/common/lib/model/Customers';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef,
  Injectable, PLATFORM_ID, Inject, Input } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { saveAs } from 'file-saver';
import { Store } from '@ngrx/store';
import { loadCustomerLogin } from 'src/app/store/actions/customer.action';
import { Router } from '@angular/router';
@Component({
  selector: 'app-image-download-modal',
  templateUrl: './download-image-modal.component.html',
  styleUrls: ['./download-image-modal.component.scss']
})
export class ImageDownloadModalComponent implements OnInit, OnDestroy {
  @Input() img: string;
  @Input() title: string;
  @Input() isDownload: boolean = false;
  @Input() titleBtn: string;
  customer: Customers;
  navigate: string;
  isNavigate: boolean = false;
  @ViewChild("qrModal", { static: false }) qrModal: TemplateRef<any>;

  public closeResult: string;
  public modalOpen: boolean = false;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private modalService: NgbModal,private store: Store,
    private router: Router
    ) { }

  ngOnInit(): void {
  }

  openModal(img:string, title: string, titleBtn: string , isDownload?: boolean , isNavigate?: boolean, navigate?: string, customer?: Customers, ) {
    this.modalOpen = true;
    this.img = img;
    this.navigate = navigate;
    this.customer = customer
    this.title = title;
    this.titleBtn = titleBtn;
    if(isNavigate) this.isNavigate = isNavigate;
    if (isDownload) this.isDownload = isDownload;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.qrModal, { 
        size: 'md',
        ariaLabelledBy: 'Video-Modal',
        centered: true,
        windowClass: 'modal fade video-modal' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        let timeout = setTimeout(() => {
          if(this.customer?._id) this.store.dispatch(loadCustomerLogin({customerId: this.customer._id}));
          (!this.navigate) ? (this.isNavigate && this.router.navigate(['/pages/login'])) :  (this.isNavigate && this.router.navigate([this.navigate]));
          clearTimeout(timeout);
         },500);
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  handleDownloadImage() {
    if (!this.isDownload || !this.img) return;
    saveAs(this.img, 'pickdi-image.png');
    this.modalService.dismissAll();
    let timeout = setTimeout(() => {
      (!this.navigate) ? (this.isNavigate && this.router.navigate(['/pages/login'])) :  (this.isNavigate && this.router.navigate([this.navigate]));
      clearTimeout(timeout);
    }, 500);
  }
  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }


}
