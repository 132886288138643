import { Component, PLATFORM_ID, Inject, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { map, delay, withLatestFrom, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './shared/services/login.service';
import { DestroySubsribeService } from './shared/services/destroySubscribe.service';
import { PWA_app_Service } from './store/services/PWA_app.service';
import { ConfirmModalComponent } from './shared/components/modal/confirm-modal/confirm-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [LoginService, DestroySubsribeService],
})
export class AppComponent implements AfterViewInit{
  @ViewChild('confirmModal') confirmModal: ConfirmModalComponent;
  // For Progressbar
  loaders = this.loader.progress$.pipe(
    delay(1000),
    withLatestFrom(this.loader.progress$),
    map(v => v[1]),
  );
  
  constructor(@Inject(PLATFORM_ID) private platformId: Object, private PWA: PWA_app_Service,
    private loader: LoadingBarService, private router: Router, private loginService : LoginService,translate: TranslateService,  private location: Location) {
      this.loginService.onCheckLogin(true);
      if (isPlatformBrowser(this.platformId)) {
      translate.setDefaultLang('en');
      translate.addLangs(['en', 'fr']);
    }
    
    
  }
  onCheckPWA() {
    this.PWA.checkUserAgent();
    this.PWA.trackStandalone();
    if ((this.PWA.isChrome && this.PWA.isMobile) || (this.PWA.isSafari && this.PWA.isIOS)) {
      window.addEventListener('beforeinstallprompt', (e) => {
        // show the add button
        this.PWA.promptIntercepted = true;
        e.preventDefault();
        this.PWA.deferredPrompt = e;
        this.PWA.promptSaved = true;
        localStorage.setItem("userInstalled", 'false');
        this.PWA.userInstalled = false;
       
      });
     
        window.addEventListener('appinstalled', (evt) => {
          // hide the add button
          this.PWA.trackInstalled();
          this.PWA.userInstalled = true;
          localStorage.setItem("userInstalled", 'true');
        });
      
    }
  }
  handleCheckPWA() {
    if ((this.PWA.isChrome && this.PWA.isMobile) || (this.PWA.isSafari && this.PWA.isIOS)) {
      if (this.PWA.deferredPromptRejected) return;
      if(localStorage.getItem('userInstalled') && JSON.parse(localStorage['userInstalled']) == false) {
        this.showModalInstallApp();
      } else {
        if(localStorage.getItem('userInstalled') && JSON.parse(localStorage['userInstalled']) == true && !this.PWA.isStandalone) this.showOpenApp();
      }
    }
  }
  showOpenApp() {
    this.confirmModal.openModal('Mở trong ứng dụng điện thoại');
  }
  showModalInstallApp() {
    this.confirmModal.openModal('Cài đặt ứng dụng vào màn hình chính');
  }
  ngAfterViewInit() {
    this.handleCheckPWA();
    this.confirmModal.resultConfirm.subscribe((result) => {
      if (result) {
        if(!this.PWA.userInstalled) {
          this.PWA.addToHomeScreen()
        }else {
            window.open('https://pickdi.com/'+ this.router.url);
        }  
      } else {
        !this.PWA.userInstalled ? window.removeEventListener('beforeinstallprompt', this.showModalInstallApp) : window.removeEventListener('appinstalled', () => {});
      }
    });
  }
  ngOnInit(): void {
    this.onCheckPWA();
   
  }

  
}
