import { Tables } from '@pickdi/common/lib/model/Tables';
import { Component, OnInit, OnDestroy, ViewChild, TemplateRef, Input,
  Injectable, PLATFORM_ID, Inject, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ProductService } from '../../../services/product.service';
import { ToastrService } from 'ngx-toastr';
import { LoadingBarService } from '@ngx-loading-bar/core';

@Component({
  selector: 'app-select-table-modal',
  templateUrl: './select-table-modal.component.html',
  styleUrls: ['./select-table-modal.component.scss']
})
export class SelectTableModalComponent implements OnInit, OnDestroy  {

 
  @ViewChild("selectTableModal", { static: false }) SelectTableModal: TemplateRef<any>;
  @Output() tableSelectOutPut : EventEmitter<Tables> = new EventEmitter<Tables>();

  public closeResult: string;
  public  tables : { floor: string, data: Tables[]};
  public tableSelect: Tables;
  public modalOpen: boolean = false;

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private router: Router, private modalService: NgbModal,
    public productService: ProductService,  private toastrService: ToastrService, private loadingBar: LoadingBarService,) { }

  ngOnInit(): void {
  }

  openModal(tables: { floor: string, data: Tables[]}, tableSelect: Tables) {
    this.modalOpen = true;
    this.tables = tables;
    this.tableSelect = tableSelect;
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.SelectTableModal, { 
        size: 'xl',
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        windowClass: 'SelectTableModal' 
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        this.tableSelectOutPut.emit(this.tableSelect);
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  handleSelectTable(table: Tables) {
    this.tableSelect = table;
    this.tableSelectOutPut.emit(this.tableSelect);
    this.modalService.dismissAll();
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    if(this.modalOpen){
      this.modalService.dismissAll();
    }
  }

}
