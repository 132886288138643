<div class="img-wrapper">
    <div class="lable-block"><span class="lable4" >on sale</span></div>
    <div class="front">
        <a>
            <img  [defaultImage]="product.image ? product.image : 'assets/images/storeDefault.jpg'" 
                  [lazyLoad]="product.image" class="img-fluid"
                  style="width: 100%;
                  height: 250px;"
                  alt="">
        </a>
    </div>
    <div class="cart-box">
        <button title="Add to cart" (click)="CartModal.openModal(product)" *ngIf="cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <button title="Add to cart" (click)="addToCart(product)" *ngIf="!cartModal">
        	<i class="ti-shopping-cart"></i> 
        </button>
        <a href="javascript:void(0)" (click)="addToWishlist(product)" title="Add to Wishlist">
        	<i class="ti-heart" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" class="mobile-quick-view" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
        <a href="javascript:void(0)" (click)="addToCompare(product)" title="Compare">
        	<i class="ti-reload" aria-hidden="true"></i>
        </a>
    </div>
    <!-- <div class="quick-view-part">
        <a href="javascript:void(0)" (click)="QuickView.openModal()" title="Quick View">
        	<i class="ti-search" aria-hidden="true"></i>
        </a>
    </div> -->
</div>
<div class="product-info">
    <bar-rating [rate]="5" [readOnly]="true"></bar-rating>
    <a>
    	<h6>{{ product?.productName | titlecase }}</h6>
    </a>
    <h6>{{ product?.prices[0].price | currency:'VND' }}</h6>
</div>

<app-quick-view #quickView [product]="product" [slug]="product.shopSlug" [currency]="currency"></app-quick-view>
<app-cart-modal #cartModal [product]="product" [currency]="currency" *ngIf="cartModal"></app-cart-modal>
<app-confirm-modal #confirmModal></app-confirm-modal>