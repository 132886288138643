import { createAction } from '@ngrx/store';

export const LoaddingAction = {
  showLoadding: '[App] Show Loadding',
  hiddenLoadding: '[App] Hidden Loadding',
  // hiddenLoaddingSuccess: '[App] Hidden Loadding Success'
};

export const showLoadding = createAction(LoaddingAction.showLoadding);

export const hiddenLoadding = createAction(LoaddingAction.hiddenLoadding);

// export const hiddenLoaddingSuccess = createAction(LoaddingAction.hiddenLoaddingSuccess);