<div class="product-card">
  <div class="img-product">
    <div class="tag-preferred-new" *ngIf="checkTags(productTag.new, product) && isProductNew" >Mới</div>
    <div class="tag-preferred-sale" *ngIf="(checkTags(productTag.sale1, product) || checkTags(productTag.sale2, product)) && isProductSale">Giảm giá</div>
    <img
    [defaultImage]="product.image ? product.image : 'assets/images/product/placeholder.jpg'" 
    onerror="this.src = 'assets/images/product/placeholder.jpg'"
    [lazyLoad]="product.image" 
    class="img-fluid lazy-loading" 
    alt=""
    />
  </div>
  <div class="product-content">
    <div class="raiting-number-order">
      <bar-rating class="rate-product" [rate]="5" [readOnly]="true"></bar-rating>
      <span class="number-order-product">999+</span>
    </div>
    <div class="name">{{product?.productName | titlecase}}</div>
    <div class="action">
      <a href="javascript:void(0)" title="Add to Wishlist" (click)="addToWishlist(product)">
        <i class="ti-heart" aria-hidden="true"></i>
      </a>
      <a href="javascript:void(0)" title="Add to cart" (click)="addToCart(product)"
      >
      <i class="ti-shopping-cart"></i>
    </a>
   
    </div>
    <!-- giảm giá nếu có -->
    <!-- <div class="promotion sponsored-promotion">
      <img src="/dist/images/discount.png" alt="sponsored-promotion" /><span
        >Giảm 30.000 đ</span
      >
    </div> -->
  </div>
</div>
<app-confirm-modal #confirmModal></app-confirm-modal>