import { Menus } from '@pickdi/common/lib/model/Menus';
import { createAction, props } from "@ngrx/store";

export const MenusActions = {
    onLoadMenusByShop: '[Menus] get menus by shop',
    onLoadMenusByShopSuccess: '[Menus] get menus by shop success',
    clearMenu: '[Menus] clear menus'
  };

  export const onLoadMenusByShop = createAction(MenusActions.onLoadMenusByShop,
    props<{shopSlug: string}>()
  );
  export const onLoadMenusByShopSuccess = createAction(MenusActions.onLoadMenusByShopSuccess,
    props<Menus>()
);
export const clearMenu = createAction(MenusActions.clearMenu
);