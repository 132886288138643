import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ECommerceInputComponent } from './e-commerce-input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [ECommerceInputComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [ECommerceInputComponent]
})
export class ECommerceInputModule { }
