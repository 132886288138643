import { Action, on } from "@ngrx/store";
import { createReducer } from "../utils/store.util";
import { clearMenu, onLoadMenusByShop, onLoadMenusByShopSuccess } from "../actions/menu.action";
import { MenusState } from "../entities/state.entity";

const initialState : MenusState = {
    menuList: null,
   };
   
   export const reducer = createReducer(
     initialState,
     on(onLoadMenusByShop, (state, action) => ({
         ...state,
       })),
    on(onLoadMenusByShopSuccess, (state, action) => ({
        ...state,
        menuList: action?.menus,
    })),
    on(clearMenu, (state, action) => ({
      ...state,
      menuList: null,
  })),
   );
   export function menusReducer(state: MenusState | undefined, action: Action) {
     return reducer(state, action);
   }