import { Action } from '@ngrx/store';
import { createReducer, on } from '../utils/store.util';
import { IsLoaddingState } from '../entities/state.entity';
import { hiddenLoadding, showLoadding } from '../actions/loadding.action';

const initialState: IsLoaddingState = {
 isLoadding: false,
};

export const reducer = createReducer(
  initialState,
  on(showLoadding, (state, action) => ({
    isLoadding: true
  })),
  on(hiddenLoadding, (state, action) => ({
    isLoadding: false
  })),
);
export function loaddingReducer(state: IsLoaddingState | undefined, action: Action) {
  return reducer(state, action)
}