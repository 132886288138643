import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, Subscription, takeUntil } from 'rxjs';
import { ProductService, state } from "../../../services/product.service";
import { Product } from "../../../classes/product";
import { RootState } from 'src/app/store/entities/state.entity';
import { Store } from '@ngrx/store';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { loadCustomerLogin } from 'src/app/store/actions/customer.action';
import * as _ from 'lodash';
@Component({
  selector: 'app-cart-variation',
  templateUrl: './cart-variation.component.html',
  styleUrls: ['./cart-variation.component.scss']
})
export class CartVariationComponent implements OnInit, OnDestroy {

  @Input() direction: string = 'right'; // Default Direction Right
  private subscription: Subscription;
  public products: Product[] = [];
  customerId: string;
  constructor(public productService: ProductService, private store: Store<RootState>, private destroy: DestroySubsribeService) {
    this.subscription = this.productService.cartItems$.subscribe((items) => {
      this.products = items;
    });
    this.productService.cartItems.subscribe(response => this.products = response);
  }

  ngOnInit(): void {
    this.productService.OpenCart = false;
    this.onLoadCustomerLogin();
    if(this.customerId) this.store.dispatch(loadCustomerLogin({customerId: this.customerId}));
    this.store.select( state => state.cart.listCart)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(listCart => {
      if(listCart && listCart.length == 0 && !this.customerId) return;
       this.products = listCart;
       state.cart = _.cloneDeep(listCart);
    })
  }
  onLoadCustomerLogin() {
    this.store.select( state => state.customer.customerLogin)
    .pipe(takeUntil(this.destroy.destroySub$))
    .subscribe(customerLogin => {
      if (!customerLogin) return this.customerId = null;
      this.customerId = customerLogin._id;
    })
  }
  closeCart() {
    this.productService.OpenCart = false;
  }

  get getTotal(): Observable<number> {
    return this.productService.cartTotalAmount();
  }

  removeItem(product: any) {
    this.productService.removeCartItem(product, this.customerId);
  }

  ngOnDestroy(): void {
    this.closeCart();
  }

}
