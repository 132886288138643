import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, Input, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { takeUntil } from 'rxjs';
import { regexFormPattern, regexFormTel, scrollToFirstInvalidControl, validateAllFormFields, validateForm } from 'src/app/shared/functions/function-helper';
import { DestroySubsribeService } from 'src/app/shared/services/destroySubscribe.service';
import { getOtpVerification } from 'src/app/store/actions/auth.action';
import { isChangePasswordForgotPassword, isConfirmOTPForgotPassword, isForgotPassword } from 'src/app/store/actions/customer.action';
import { RootState } from 'src/app/store/entities/state.entity';

@Component({
  selector: 'app-create-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @Input() secondsRemaining: number = 59;

  passwordNewForm: FormGroup;
  telForm: FormGroup;
  isLoadding: boolean = false;
  tel: string ;
  public modalOpen: boolean = false;
  @ViewChild("createPasswordNew") createPasswordNew: TemplateRef<any>;
  validateForm = validateForm;
  regexFormPattern = regexFormPattern;
  public closeResult: string;
  telPattern: string = '^0.*';
  otp: string;
  confirmTel: boolean ;
  confirmOtp: boolean ;
  secondsRemainingStart: number;
  interval: any;
  constructor(@Inject(PLATFORM_ID) private platformId: Object,
  private modalService: NgbModal,
  private toastrService: ToastrService,
  private formBuilder: FormBuilder,
  private destroy: DestroySubsribeService,
  private cdr: ChangeDetectorRef,
  private store: Store<RootState>) { 
}
  ngOnInit(): void {
    this.secondsRemainingStart = this.secondsRemaining;
    this.telForm = this.createFormTel();
    this.passwordNewForm = this.createFormPasswordNew();
  }
  // onOtpChange(otp) {
  //   this.otp = otp;
  // }
  handlePreStep() {
   if(this.confirmTel && this.confirmOtp) {
    this.confirmTel = false;
    this.confirmOtp = false;
   } else {
    this.confirmTel = false;
   }
  }
  handleRefreshOTP() {
    this.store.dispatch(getOtpVerification({ tel: this.tel }))
  }
  handleSubmitOTP(otp: string) {
    if (otp.length === 6 && !this.confirmOtp) {
      this.isLoadding = true;
      let dataConfirm = {
        tel: this.tel,
        OTP: otp
      }
      this.store.dispatch(isConfirmOTPForgotPassword(dataConfirm));
      this.store.select( state => state.customer.isConfirmOTPForgotPassword)
        .pipe(takeUntil(this.destroy.destroySub$))
        .subscribe(isConfirmOTPForgotPassword => {
          let timeout = setTimeout(() => {
            this.isLoadding = false;
            clearTimeout(timeout);
          }, 500);
          if (!isConfirmOTPForgotPassword) return this.confirmOtp = false;
          this.confirmOtp = true;
        })
      return
    } 
    this.toastrService.error('Vui lòng nhập vào 6 mã code');
  }
  
  openModal() {
    this.modalOpen = true;
    this.confirmTel = false;
    this.confirmOtp = false;
    this.tel = '';
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalService.open(this.createPasswordNew, { 
        size: 'md',
        backdrop: 'static',
        keyboard: false,
        centered: true,
        windowClass: 'bd-example-modal-md theme-modal agem'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
        // this.resultConfirm.emit([false]);
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  handleSubmitForm() {
    if(!this.confirmTel && !this.confirmOtp) {
      this.handleConfirmTel();
    } else {
      this.handleChangePassword();
    }
  }
  handleChangePassword() {
    if(this.passwordNewForm.valid && this.confirmOtp) {
      let dataChange = {
        tel: this.tel,
        password: this.passwordNewForm.value.passwordNew,
        confirmPassword: this.passwordNewForm.value.passwordConfirm
      }
      this.store.dispatch(isChangePasswordForgotPassword(dataChange));
      this.store.select( state => state.customer.isChangePasswordForgotPassword)
      .pipe(takeUntil(this.destroy.destroySub$))
      .subscribe(isChangePasswordForgotPassword => {
       
        if (!isChangePasswordForgotPassword) return;
        let timeout = setTimeout(() => {
          this.isLoadding = false;
          this.toastrService.success('Thay đổi mật khẩu thành công');
          this.modalService.dismissAll();
          clearTimeout(timeout);
        }, 500);
      })

    } else {
      validateAllFormFields(this.passwordNewForm);
      scrollToFirstInvalidControl(this.passwordNewForm);
    }
  }
  handleConfirmTel() {
    if(this.telForm.valid && !this.confirmTel) {
      this.isLoadding = true;
      let telInput = this.telForm.value.telConfirm.replaceAll(' ', '');
      this.tel = telInput;
      this.store.dispatch(isForgotPassword({tel: telInput}));
      this.store.select( state => state.customer.isForgotPassword)
      .pipe(takeUntil(this.destroy.destroySub$))
      .subscribe(isForgotPassword => {
        let timeout = setTimeout(() => {
          this.isLoadding = false;
          clearTimeout(timeout);
        }, 500);
        if (!isForgotPassword) return this.confirmTel = false;
        this.confirmTel = true;
      })
    } else {
      validateAllFormFields(this.telForm);
      scrollToFirstInvalidControl(this.telForm);
    }
  }
  handleNoConfirm() {
    this.modalService.dismissAll();
  }
  createFormTel(): FormGroup {
    return this.formBuilder.group({
      telConfirm: ['', [Validators.required, regexFormTel]],
    })
  }
  createFormPasswordNew(): FormGroup {
    return this.formBuilder.group({
      passwordNew: ['', [Validators.minLength(6), Validators.maxLength(32)]],
      passwordConfirm: [''],
    },{ validator: this.checkPasswords })
  }
  checkPasswords(group: FormGroup) {
    let pass = group.controls.passwordNew.value;
    let confirmPass = group.controls.passwordConfirm.value;  
    return pass === confirmPass ? null : { notSame: true }
  }
}
