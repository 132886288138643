import { clearConfirmTelChange, confirmChangeTelCustomer, isChangePasswordForgotPassword, isChangePasswordForgotPasswordSuccess, isConfirmOTPForgotPassword, isConfirmOTPForgotPasswordSuccess, isConfirmPassword, isForgotPassword, isForgotPasswordSuccess } from './../actions/customer.action';
import { Action, on } from "@ngrx/store";
import { createReducer } from "../utils/store.util";
import { CustomersState } from "../entities/state.entity";
import { changePasswordNew, changePasswordNewSuccess, changeTelCustomer, clearStateCustomer, confirmChangeTelCustomerSuccess, loadCustomerLogin, loadCustomerLoginSuccess , updataInfoCustomer } from "../actions/customer.action";

const initialState : CustomersState= {
   message: null,
   customerLogin: {},
   isConfirmTel : false,
   isConfirmPassword : false,
   isForgotPassword: false,
   isConfirmOTPForgotPassword: false,
   isChangePasswordForgotPassword: false
  };
  
  export const reducer = createReducer(
    initialState,
    on(loadCustomerLogin, (state, action) => ({
        ...state,
      })),
      on(loadCustomerLoginSuccess, (state, action) => ({
        ...state,
        customerLogin: action,
      })),
      on(updataInfoCustomer ,(state , action)=>({
        ...state,
      })),
      on(changeTelCustomer ,(state , action)=>({
        ...state,
      })),
      on(changePasswordNew ,(state , action)=>({
        ...state,
      })),
      on(changePasswordNewSuccess, (state, action) => ({
        ...state,
        customerLogin: action,
      })),
      on(confirmChangeTelCustomer ,(state , action)=>({
        ...state,
      })),
      on(confirmChangeTelCustomerSuccess, (state, action) => ({
        ...state,
        isConfirmTel: action,
      })),
      on(clearConfirmTelChange, (state, action) => ({
        ...state,
        isConfirmTel: false,
      })),
      on(clearStateCustomer, (state, action) => ({
        ...state,
        customerItem: null,
        customerLogin: {},
      })),
      on(isConfirmPassword, (state, action) => ({
        ...state,
       isConfirmPassword: action.isConfirm
      })),
      on(isForgotPassword, (state, action) => ({
        ...state
      })),
      on(isForgotPasswordSuccess, (state, action) => ({
        ...state,
        isForgotPassword: action.isForgotPassword
      })),
      on(isConfirmOTPForgotPassword, (state, action) => ({
        ...state
      })),
      on(isConfirmOTPForgotPasswordSuccess, (state, action) => ({
        ...state,
        isConfirmOTPForgotPassword: action.isConfirmOTPForgotPassword
      })),

      on(isChangePasswordForgotPassword, (state, action) => ({
        ...state
      })),
      on(isChangePasswordForgotPasswordSuccess, (state, action) => ({
        ...state,
        isChangePasswordForgotPassword: action.isChangePasswordForgotPassword
      })),

  );
  export function customerReducer(state: CustomersState | undefined, action: Action) {
    return reducer(state, action);
  }