<ng-template class="theme-modal" #otpModal let-modal>
    <div class="modal-content quick-view-modal">
        <div class="modal-body ">
        <ng-container *ngIf="!passwordResult else password">
                  <app-otp-layout [tel]="tel" [secondsRemaining]="59" (handleRefreshOTP)="handleRefreshOTP()" (handleSubmitOTP)="handleSubmitOTP($event)"></app-otp-layout>
                </ng-container>
            </div>
        <ng-template #password>
            <div class="d-flex justify-content-center align-items-center flex-column">
                <h2 class="mt-2">Mật khẩu của bạn</h2>
                <h3 class="mt-2 passwordResult" (click)="copyTextToClipboard()">{{passwordResult}} <i class="fa fa-clipboard" aria-hidden="true"></i> </h3> 
                <button class="btn btn-solid rounded mt-3" (click)="handleOkPassword()" >Đồng ý</button>
            </div>
           
        </ng-template>
        
    </div>
</ng-template>
<app-image-download-modal #imageDownload></app-image-download-modal>
