import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import WebSocketClientHelper from "src/app/core/WebSocketClientHelper";
import { RootState } from 'src/app/store/entities/state.entity';

@Injectable({
  providedIn: 'root'
})
export class WebSocketService {
  socketClient: WebSocketClientHelper;
  count: number = 0;

  constructor(
    private store: Store<RootState>,
  ) {
  }

  initialiseSocket() {
    this.store
      .select(state => state.customer.customerLogin)
      .subscribe(customer => {
        if (!customer) return;
        this.socketClient = new WebSocketClientHelper(customer._id, this.store);
        this.socketClient.Initialise();
        this.socketClient.AddMessageHandler(this);
      })
  }
  closeWebSocket() {
    if(this.socketClient?.enabled) this.socketClient.enabled = false;
  }
 
}