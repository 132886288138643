import { Shops } from '@pickdi/common/lib/model/Shops';
import ShopsManager from '@pickdi/common/lib/model/manager/ShopsManager';
import { HttpStatus } from '@pickdi/common/lib/base/HttpStatus';
import { Injectable } from '@angular/core';
import { LoadingBarService } from '@ngx-loading-bar/core';
import { Store } from '@ngrx/store';
import { showLoadding } from '../actions/loadding.action';
@Injectable()
export class ShopsService {
  constructor(
    private loadingBar: LoadingBarService,
    private store: Store
    ) { }
  async onLoadInfoShopBySlug(actionId): Promise<HttpStatus<Shops>>  {
    this.store.dispatch(showLoadding());
    const menusManager = new ShopsManager(null);
    menusManager.classInfo.endPoint = 'eCommerce/shop';
    menusManager.classInfo.endPoint = menusManager.classInfo.endPoint.concat('/getDetail');
    let slug = actionId.slug;
    return menusManager.get(slug);
  }
  async onLoadAllVoucherShop(actionId): Promise<HttpStatus<Shops>>  {
    const menusManager = new ShopsManager(null);
    menusManager.classInfo.endPoint = 'eCommerce/shop';
    menusManager.classInfo.endPoint = menusManager.classInfo.endPoint.concat('/getAllDiscount');
    let slug = actionId.slug;
    return menusManager.get(slug);
  }
  async onLoadAllShop(filter): Promise<HttpStatus<Shops>>{
    this.store.dispatch(showLoadding());
    const menuManager = new ShopsManager(null);
    menuManager.classInfo.endPoint = "eCommerce/shop";
    menuManager.classInfo.endPoint = menuManager.classInfo.endPoint.concat('/getAllShop');
    return menuManager.search(filter)
  }
  async onLoadAllProduct(filter): Promise<HttpStatus<Shops>>{
    const menuManager = new ShopsManager(null);
    menuManager.classInfo.endPoint = "eCommerce";
    menuManager.classInfo.endPoint = menuManager.classInfo.endPoint.concat('/getAllProduct');
    return menuManager.search(filter);
  }
  async onFilterProductOrShop(filter): Promise<HttpStatus<Shops>>{
    this.store.dispatch(showLoadding());
    const menuManager = new ShopsManager(null);
    menuManager.classInfo.endPoint = "eCommerce";
    menuManager.classInfo.endPoint = menuManager.classInfo.endPoint.concat('/filterProductOrShop');
    return menuManager.search({filter: filter});
  }

}